import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadThemes } from '../../../../actions/actionCreators'
import ReactHTMLDatalist from 'react-html-datalist'
import { selectThemeFilter } from '../../../../actions/actions'

export default function ThemesSelect () {
  const [name, setName] = useState('')

  const dispatch = useDispatch()
  const themes = useSelector(({ themes }) => themes.themes, shallowEqual)

  const newOption = theme => {
    return {
      text: theme.name, value: theme.id
    }
  }
  const newTheme = option => {
    return {
      name: option.text, id: option.value
    }
  }

  useEffect(() => {
    dispatch(loadThemes(name))
  }, [dispatch, name])

  const handleChange = e => {
    if (e.target.value === '') {
      setName(e.target.text)
      return
    }
    dispatch(selectThemeFilter(newTheme(e.target)))
  }
  return (
    <>
      <label htmlFor='theme-input' className='col-md-1 col-form-label'>Theme</label>
      <div className='col-md-3'>
        <ReactHTMLDatalist
          name='theme_id'
          onChange={handleChange}
          classNames='form-control'
          options={themes.map(newOption)}
        />
      </div>
    </>
  )
}
