import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { getPrices } from '../../api'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51IW26dDRPRNpgP2gS761doYDczxglXlwyQugTkU3e2MJmeFymyKJzFq65mm3ShL029vai2QTVMcxw9kld0ObpaLg00PewxzEG6')

const CheckoutForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (error) {
      console.log('[error]', error)
    } else {
      console.log('[PaymentMethod]', paymentMethod)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4'
              }
            },
            invalid: {
              color: '#9e2146'
            }
          }
        }}
      />
      <button type='submit' disabled={!stripe}>
        Pay
      </button>
    </form>
  )
}

export const Prices = () => {
  const [priceLookupKey, setPriceLookupKey] = useState(null)
  const [prices, setPrices] = useState([])

  useEffect(() => {
    getPrices().then((result) => {
      setPrices(result.data)
    })
  }, [])

  if (priceLookupKey) {
    return (
      <Redirect to={{
        pathname: `/subscribe/${priceLookupKey}`
      }}
      />
    )
  }

  return (
    <div>
      <h1>Select a plan</h1>
      <div className='price-list'>
        {prices.map((p) => (

          <div key={p.id}>
            <h3>{p.recurring_period}</h3>

            <p>
              {p.price / 100} {p.currency} / {p.recurring_period}
            </p>

            <button onClick={() => setPriceLookupKey(p.id)}>
              Select
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
