import React from 'react'

const Alert = ({ show, children, severity }) => {
  const showHideClassName = show ? 'd-block' : 'd-none'
  return (
    <div className={showHideClassName}>
      <div className='alert alert-success' role={severity}>
        {children}
      </div>
    </div>
  )
}

export default Alert
