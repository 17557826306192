import { abs, max, min } from 'mathjs'

export function formatDateISOYear (t) {
  return t.getFullYear()
}

export function identity (t) {
  return t
}

export function formatDateISOMonth (t) {
  const [y, m] = [t.getFullYear(), t.getMonth()]
  return formatISOMonth(y, m)
}

export function formatISOMonth (y, m) {
  return y + '-' + (m < 9 ? ('0' + (m + 1)) : (m + 1))
}

export function nextMonthString (y, m) {
  if (m === 11) {
    return formatISOMonth(y + 1, 0)
  }
  return formatISOMonth(y, m + 1)
}

export function previousMonthString (y, m) {
  if (m === 0) {
    return formatISOMonth(y - 1, 11)
  }
  return formatISOMonth(y, m - 1)
}

export function formatDateISOString (t) {
  const [y, m, d] = [t.getFullYear(), t.getMonth(), t.getDate()]
  return y + '-' + (m < 9 ? ('0' + (m + 1)) : (m + 1)) + '-' + (d < 10 ? ('0' + d) : d)
}

export function getRequiredDateFormat (v) {
  const t = new Date(v)
  return formatDateISOString(t)
}

export function getRequiredMonthFormat (v) {
  const t = new Date(v)
  return formatDateISOMonth(t)
}

export function getPerf (from, to) {
  if (from === 0 || from === undefined || from.y === undefined || to === undefined || to.y === undefined) {
    return undefined
  }
  return (to.y - from.y) / from.y
}

export function eq (a, f, v) {
  return a.filter(d => f(d.t) === v)
}

export function lessEq (a, f, v) {
  return a.filter(d => f(d.t) <= v)
}

export function moreEq (a, f, v) {
  return a.filter(d => f(d.t) >= v)
}

export function more (a, f, v) {
  return a.filter(d => f(d.t) > v)
}

export function first (a, f, v, c) {
  const vs = c(a, f, v)
  if (vs.length !== 0) {
    return vs[0]
  }
  return undefined
}

export function last (a, f, v, c) {
  const vs = c(a, f, v)
  if (vs.length !== 0) {
    return vs[vs.length - 1]
  }
  return undefined
}

export function maxi (a, b) {
  if (a === undefined) {
    return b
  }
  if (b === undefined) {
    return a
  }
  return max(a, b)
}

export function mini (a, b) {
  if (a === undefined) {
    return b
  }
  if (b === undefined) {
    return a
  }
  return min(a, b)
}

export function commonStart (a, b) {
  if (a.length === 0 || a.length === 0) {
    return undefined
  }
  let i = 0
  let j = 0
  while (i < a.length - 1 && j < b.length - 1 && a[i].x !== b[j].x) {
    if (a[i].x < b[j].x) {
      i++
    } else if (a[i].x > b[j].x) {
      j++
    }
  }
  if (i >= a.length - 1 || j >= b.length - 1) {
    return undefined
  }
  if (a[i].x === b[j].x) {
    return a[i].x
  }
  return undefined
}

export function getRange (a) {
  if (a === undefined || a.data === undefined || a.data.length === 0) {
    return [undefined, undefined]
  }
  return [a.data[0].t, a.data[a.data.length - 1].t]
}

export function commonRange (a, b) {
  const [aMin, aMax] = [a[0], a[1]]
  const [bMin, bMax] = getRange(b)

  let cMin, cMax
  if (bMin === undefined || aMin > bMin) {
    cMin = aMin
  } else {
    cMin = bMin
  }
  if (bMax === undefined || aMax > bMax) {
    cMax = aMax
  } else {
    cMax = bMax
  }

  if (cMin !== undefined && cMax !== undefined && cMin > cMax) {
    return [undefined, undefined]
  }

  return [cMin, cMax]
}

export function chartAutoFilter (d, r, scope) {
  if (r.length === 0) {
    return d
  }
  const t = new Date(r[r.length - 1].t)
  return chartFilter(d, t, scope)
}

export function chartFilter (d, t, scope) {
  switch (scope) {
    case '1m':
      t = t.setMonth(t.getMonth() - 1)
      return moreEq(d, identity, t)
    case '3m':
      t = t.setMonth(t.getMonth() - 3)
      return moreEq(d, identity, t)
    case '6m':
      t = t.setMonth(t.getMonth() - 6)
      return moreEq(d, identity, t)
    case '1y':
      t = t.setFullYear(t.getFullYear() - 1)
      return moreEq(d, identity, t)
    case 'ytd':
      t = t.setMonth(0, -1)
      return moreEq(d, identity, t)
    case '2y':
      t = t.setFullYear(t.getFullYear() - 2)
      return moreEq(d, identity, t)
    default:
      return d
  }
}

export function normalizePerformance (d) {
  if (d.length === 0) {
    return d
  }
  return d.map((d2) => {
    return {
      x: d2.x,
      t: d2.t,
      y: d2.y / d[0].y * 100
    }
  })
}

export function normalizePerformanceToDate (d, t) {
  if (d.length === 0) {
    return d
  }
  const [norm, _] = d.reduce((previous, next) => {
    const previousT = previous[1]
    if (previousT === undefined || abs(t.getTime() - next.t.getTime()) < abs(t.getTime() - previousT.getTime())) {
      return [next.y, next.t]
    }
    return previous
  }, [undefined, undefined])

  return d.map((d2) => {
    return {
      x: d2.x,
      t: d2.t,
      y: d2.y / norm * 100
    }
  })
}

export function normalizePerformances (ds) {
  if (ds.length === 0) {
    return ds
  }
  const [cMin, cMax] = ds.reduce((a, b) => {
    return commonRange(a, b)
  }, [undefined, undefined])

  if (cMin === undefined) {
    return ds.map((d2) => (
      {
        id: d2.id,
        data: normalizePerformance(d2.data)
      }
    ))
  }

  return ds.map((d2) => (
    {
      id: d2.id,
      data: normalizePerformanceToDate(d2.data, cMin)
    }
  ))
}
