export function formatCaret (d) {
  if (d === undefined) {
    return
  }
  if (d > -0.001 && d < 0.001) {
    return <i style={{ color: 'blue' }} className='mdi mdi-equal' />
  }
  if (d <= -0.5) {
    return <i style={{ color: 'red' }} className='mdi mdi-chevron-triple-down' />
  }
  if (d <= -0.1) {
    return <i style={{ color: 'red' }} className='mdi mdi-chevron-double-down' />
  }
  if (d < 0) {
    return <i style={{ color: 'red' }} className='mdi mdi-chevron-down' />
  }
  if (d >= 0.5) {
    return <i style={{ color: 'green' }} className='mdi mdi-chevron-triple-up' />
  }
  if (d >= 0.1) {
    return <i style={{ color: 'green' }} className='mdi mdi-chevron-double-up' />
  }
  if (d >= 0) {
    return <i style={{ color: 'green' }} className='mdi mdi-chevron-up' />
  }
}
