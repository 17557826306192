import React from 'react'
import CertificateList from '../CertificateList'
import Page from '../../Page'

export default function Explore () {
  return (
    <Page title='myAMC.ch - Explore'>

      <div className='header-bg'>
        <header id='topnav2'>
          <div className='container-fluid'>
            <div className='row align-items-center '>
              <div className='col-md-8'>
                <h6><i className='dripicons-search mr-2' />Explore</h6>
                <p>Browse through all our certificates</p>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <CertificateList />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Page>
  )
}
