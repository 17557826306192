import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { removeProviderFilter } from '../../../../actions/actions'

export default function ProvidersFilters () {
  const providerFilters = useSelector(({ certFilters }) => certFilters.providers, shallowEqual)
  const dispatch = useDispatch()

  const onRemoveProviderClick = (providerId) => {
    dispatch(removeProviderFilter(providerId))
  }
  return (
    <div className='col-md-2'>
      {providerFilters.map((item, i) => {
        return (
          <span key={i} className='badge badge-pill badge-light' style={{ cursor: 'pointer' }} onClick={(e) => onRemoveProviderClick(item.id)}>{item.name}
            <i key={i} className='mdi mdi-cancel' />
          </span>
        )
      })}
    </div>
  )
}
