import React, { useEffect, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadCertsPerf } from '../../actions/actionCreators'
import Loading from '../loading'
import {
  chartFilter,
  getRequiredDateFormat,
  getRequiredMonthFormat,
  normalizePerformances
} from '../../Formats/Time'

const CompareTimeline = () => {
  const dispatch = useDispatch()
  const { certsPerf, isLoading } = useSelector(({ certsPerf }) => certsPerf, shallowEqual)
  const certCompare = useSelector(({ certCompare }) => certCompare, shallowEqual)
  const certs = useSelector(({ certs }) => certs.certs, shallowEqual)

  const [showFrom0, setShowFrom0] = useState(true)
  const toggleShowFrom0 = () => setShowFrom0(!showFrom0)

  const [showLegend, setShowLegend] = useState(true)
  const toggleShowLegend = () => setShowLegend(!showLegend)

  const [chartScope, setChartScope] = useState('all')
  const setChartScopeAll = () => setChartScope('all')
  const setChartScope1M = () => setChartScope('1m')
  const setChartScope3M = () => setChartScope('3m')
  const setChartScope6M = () => setChartScope('6m')
  const setChartScopeYTD = () => setChartScope('ytd')
  const setChartScope1Y = () => setChartScope('1y')
  const setChartScope2Y = () => setChartScope('2y')

  const divStyle = {
    height: '600px',
    marginBottom: '20px'
  }

  let cert_ids
  if (certCompare.ids.length === 0) {
    cert_ids = certs.map((d) => d.id).slice(0, 5) // TODO fix redundant value of 5 between Detail and Timeline
  } else {
    const ids = []
    certs.map((c) => {
      if (certCompare.ids.filter((i) => (i === c.id)).length !== 0) {
        ids.push(c.id)
      }
    })
    cert_ids = ids
  }

  useEffect(() => {
    dispatch(loadCertsPerf(cert_ids))
  }, [dispatch, certCompare, certs])

  if (isLoading) {
    return <Loading />
  }

  if (certsPerf === undefined || certsPerf.items === undefined || certsPerf.items.length === 0) {
    return (
      <div className='alert alert-warning' role='alert'>
        No performance data available
      </div>
    )
  }

  const latestAvailableDate = certsPerf.items.map((d) => {
    if (d.items.length) {
      return d.items[d.items.length - 1].date
    } else {
      return undefined
    }
  }).reduce(function (rv, x) {
    if (rv === undefined) {
      return x
    }
    if (x === undefined) {
      return rv
    }
    if (x > rv) {
      return x
    }
    return rv
  }, undefined)

  const getName = (c, id) => {
    const v = certs.filter((c) => c.id === id)
    if (v.length === 0) {
      return ''
    } else {
      return v[0].name + '|' + id
    }
  }

  const allData = normalizePerformances(certsPerf.items.map((d) => {
    const vs = d.items.map(
      (d2) => {
        return {
          x: d2.date,
          t: new Date(d2.date),
          y: d2.price
        }
      }
    )
    return {
      id: getName(certs, d.id),
      data: chartFilter(vs, new Date(latestAvailableDate), chartScope)
    }
  }).filter((d) => (d.id !== '')))

  let totalTime, tickFormat

  const minTime = allData.map((d) => {
    if (d.data.length) {
      return d.data[d.data.length - 1].t
    } else {
      return undefined
    }
  }).reduce(function (rv, x) {
    if (rv === undefined) {
      return x
    }
    if (x === undefined) {
      return rv
    }
    if (x < rv) {
      return x
    }
    return rv
  }, undefined)

  const maxTime = allData.map((d) => {
    if (d.data.length) {
      return d.data[d.data.length - 1].t
    } else {
      return undefined
    }
  }).reduce(function (rv, x) {
    if (rv === undefined) {
      return x
    }
    if (x === undefined) {
      return rv
    }
    if (x > rv) {
      return x
    }
    return rv
  }, undefined)

  // handle case when month formatting is repeating itself in the x axis
  tickFormat = getRequiredMonthFormat
  if (maxTime !== undefined && minTime !== undefined) {
    totalTime = (maxTime.t - minTime.t) / (1000 * 3600 * 24 * 365.25)
    if (totalTime <= 1) {
      tickFormat = getRequiredDateFormat
    } else {
      tickFormat = getRequiredMonthFormat
    }
  }

  const colors = ['#e51b23',
    'rgba(34,34,34,0.34)',
    '#0e86e7',
    '#fdaf27',
    '#20d4b6',
    '#000000'
  ]

  return (
    <div className='card'>
      <div className='card-body' style={divStyle}>
        <h4 className='mt-0 header-title mb-4'>Track Record</h4>

        <span>
          <span style={{ marginRight: '8px', marginLeft: '8px' }}>Range:</span>
          <span onClick={setChartScope1M} className={'btn-chart' + (chartScope === '1m' ? ' btn-chart-active' : '')}>1&nbsp;month</span>
          <span onClick={setChartScope3M} className={'btn-chart' + (chartScope === '3m' ? ' btn-chart-active' : '')}>3&nbsp;months</span>
          <span onClick={setChartScope6M} className={'btn-chart' + (chartScope === '6m' ? ' btn-chart-active' : '')}>6&nbsp;months</span>
          <span onClick={setChartScopeYTD} className={'btn-chart' + (chartScope === 'ytd' ? ' btn-chart-active' : '')}>ytd</span>
          <span onClick={setChartScope1Y} className={'btn-chart' + (chartScope === '1y' ? ' btn-chart-active' : '')}>1&nbsp;year</span>
          <span onClick={setChartScope2Y} className={'btn-chart' + (chartScope === '2y' ? ' btn-chart-active' : '')}>2&nbsp;years</span>
          <span onClick={setChartScopeAll} className={'btn-chart' + (chartScope === 'all' ? ' btn-chart-active' : '')}>max</span>
        </span>

        <span>
          <span style={{ marginRight: '8px', marginLeft: '8px' }}>Ymin:</span>
          <span
            onClick={toggleShowFrom0}
            className='btn-chart'
          >
            {showFrom0 ? 'auto' : '‎ ‎0‎ ‎'}
          </span>
        </span>

        <span>
          <span style={{ marginRight: '8px', marginLeft: '8px' }}>Legend:</span>
          <span
            onClick={toggleShowLegend}
            className='btn-chart'
          >
            {showLegend ? 'hide' : 'show'}
          </span>
        </span>

        <ResponsiveLine
          data={allData}
          colors={colors}
          lineWidth={1.3}
          margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d',
            precision: 'day'
          }}
          yScale={{ type: 'linear', min: showFrom0 ? 0 : 'auto', max: 'auto', stacked: false, reverse: false }}
          yFormat=' >-.2f'
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            // tickSize: 5,
            tickCount: 5,
            // tickValues: 'every month',
            tickPadding: 5,
            tickRotation: 30,
            format: (d) => `${tickFormat(d)}`,
            opacity: 1
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          enablePoints={false}
              // gridXValues={ticks} // TODO automatically generate tick values
          pointSize={0}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          tooltip={
                  (x) => {
                    return (
                      <span>
                        <div>{x.point.serieId.split('|')[0]}</div>
                        <div>Date: <b>{getRequiredDateFormat(x.point.data.x)}</b></div>
                        <div>Normalized Perf: <b>{x.point.data.yFormatted}</b></div>
                      </span>
                    )
                  }
              }
          useMesh
          legends={showLegend
            ? [
                {
                  anchor: 'bottom-left',
                  direction: 'column',
                  data: allData.map((d, index) => ({
                    color: colors[index],
                    id: d.id,
                    label: d.id.split('|')[0]
                  })),
                  justify: false,
                  translateX: 10,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 200,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]
            : []}
        />

      </div>
      <div className='card-body'>
        <div style={{ float: 'right', paddingRight: '50px' }}>
          <span style={{ marginRight: '26px', lineHeight: '32px', verticalAlign: 'text-top' }}>Data by</span>
          <img src='/assets/images/six.png' className='logo-lg' alt='' height='26' />
        </div>
      </div>
    </div>
  )
}

export default CompareTimeline
