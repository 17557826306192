import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadTags } from '../../../../actions/actionCreators'
import ReactHTMLDatalist from 'react-html-datalist'
import { selectTagFilter } from '../../../../actions/actions'

export default function TagsSelect () {
  const [name, setName] = useState('')

  const dispatch = useDispatch()
  const tags = useSelector(({ tags }) => tags.tags, shallowEqual)

  const newOption = tag => {
    return {
      text: tag.name, value: tag.id
    }
  }
  const newTag = option => {
    return {
      name: option.text, id: option.value
    }
  }

  useEffect(() => {
    dispatch(loadTags(name))
  }, [dispatch, name])

  const handleChange = e => {
    if (e.target.value === '') {
      setName(e.target.text)
      return
    }
    dispatch(selectTagFilter(newTag(e.target)))
  }
  return (
    <>
      <label htmlFor='tag-input' className='col-md-1 col-form-label'>Tag</label>
      <div className='col-md-3'>
        <ReactHTMLDatalist
          name='tag_id'
          onChange={handleChange}
          classNames='form-control'
          options={tags.map(newOption)}
        />
      </div>
    </>
  )
}
