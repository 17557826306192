import React from 'react'

const ModalContact = ({ handleClose, show, children, handleAction, title, contentChange }) => {
  const showHideClassName = show ? 'modal d-block' : 'modal d-none'
  const write = (event) => {
    contentChange(event.target.value)
  }
  return (
    <div className={showHideClassName} tabIndex='-1' role='dialog' style={{ backgroundColor: 'rgba(50,50,50,0.7)' }}>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{title}</h5>
            <button type='button' className='close' onClick={handleClose} aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            {children}
            <textarea onChange={write} className='w-100' />
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-primary' onClick={handleAction}>Send</button>
            <button type='button' className='btn btn-secondary' onClick={handleClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalContact
