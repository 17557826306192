import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ResponsiveLine } from '@nivo/line'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadBenchPerf, loadCertPerf } from '../../actions/actionCreators'
import Loading from '../loading'
import { formatNumber, formatPercentage } from '../../Formats/Number'
import {
  chartAutoFilter,
  commonStart,
  eq,
  first,
  formatDateISOMonth,
  formatDateISOString,
  formatDateISOYear,
  formatISOMonth,
  getPerf,
  getRequiredDateFormat,
  getRequiredMonthFormat,
  last,
  lessEq,
  maxi,
  mini,
  moreEq,
  previousMonthString
} from '../../Formats/Time'

const CertificateTimeline = () => {
  const dispatch = useDispatch()
  const { certificateId } = useParams()
  const certInfo = useSelector(({ certInfo }) => certInfo.certInfo, shallowEqual)
  const { certPerf, isLoading } = useSelector(({ certPerf }) => certPerf, shallowEqual)
  const { benchPerf, _ } = useSelector(({ benchPerf }) => benchPerf, shallowEqual)

  const [showBenchmark, setShowBenchmark] = useState(true)
  const toggleShowBenchmark = () => setShowBenchmark(!showBenchmark)

  const [showFrom0, setShowFrom0] = useState(true)
  const toggleShowFrom0 = () => setShowFrom0(!showFrom0)

  const [chartScope, setChartScope] = useState('all')
  const setChartScopeAll = () => setChartScope('all')
  const setChartScope1M = () => setChartScope('1m')
  const setChartScope3M = () => setChartScope('3m')
  const setChartScope6M = () => setChartScope('6m')
  const setChartScopeYTD = () => setChartScope('ytd')
  const setChartScope1Y = () => setChartScope('1y')
  const setChartScope2Y = () => setChartScope('2y')

  const divStyle = {
    height: '600px',
    marginBottom: '20px'
  }

  useEffect(() => {
    dispatch(loadCertPerf(certificateId))
  }, [certificateId, dispatch])

  useEffect(() => {
    dispatch(loadBenchPerf(certInfo.benchmark_id === undefined ? 0 : certInfo.benchmark_id))
  }, [certInfo, dispatch])

  if (isLoading) {
    return <Loading />
  }
  if (certPerf === undefined || certPerf.items === undefined || certPerf.items.length === 0) {
    return (
      <div className='alert alert-warning' role='alert'>
        No performance data available
      </div>
    )
  }
  const data = certPerf.items.map(
    (d) => {
      return {
        x: d.date,
        t: new Date(d.date),
        y: d.price,
        cid: d.cert_id,
        cn: d.cert_name,
        cit: d.cert_type
      }
    }
  )

  let benchData
  if (benchPerf === undefined || benchPerf.items === undefined || benchPerf.items.length === 0) {
    benchData = []
  } else {
    benchData = benchPerf.items.map(
      (d) => {
        return {
          x: d.date,
          t: new Date(d.date),
          y: d.price,
          cit: 'Benchmark'
        }
      }
    )
  }

  let maxValue = data[0]
  let maxDrawdown = 0
  data.forEach((v) => {
    if (v.y > maxValue.y) {
      maxValue = v
    } else {
      const p = getPerf(maxValue, v)
      if (p < maxDrawdown) {
        maxDrawdown = p
      }
    }
  })

  const earliestAvailableYear = formatDateISOYear(data[0].t)
  const earliestAvailableYearMonth = formatDateISOMonth(data[0].t)
  const lastAvailableYear = formatDateISOYear(data[data.length - 1].t)
  const yearsList = []
  for (let y = earliestAvailableYear; y <= lastAvailableYear; y++) {
    yearsList.push(y)
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const trackMonthPerf = []
  const trackYearPerf = []

  let bestMonth, worstMonth
  let lastYear, lastYearMonth
  // TODO compute sharpe ratio per year and display in table
  yearsList.forEach(function (y, iy) {
    const yearMonthPerf = []
    lastYear = y
    months.forEach(function (m, i) {
      let from = last(data, formatDateISOMonth, previousMonthString(y, i), eq)
      if (from === undefined && formatISOMonth(y, i) === earliestAvailableYearMonth) {
        from = first(data, formatDateISOMonth, formatISOMonth(y, i), eq)
      }
      const to = last(data, formatDateISOMonth, formatISOMonth(y, i), eq)
      const p = getPerf(from, to)
      if (to === undefined || to.y === undefined || to.y !== data[data.length - 1].t) {
        bestMonth = maxi(bestMonth, p)
        worstMonth = mini(worstMonth, p)
      }
      yearMonthPerf.push(p)
      if (p !== undefined) {
        lastYearMonth = formatISOMonth(y, i + 1)
      }
    })

    trackMonthPerf.push(yearMonthPerf)
    let from = last(data, formatDateISOYear, y - 1, eq)
    if (from === undefined && y === earliestAvailableYear) {
      from = first(data, formatDateISOYear, y, eq)
    }
    const to = last(data, formatDateISOYear, y, eq)
    trackYearPerf.push(getPerf(from, to))
  })

  const filteredData = chartAutoFilter(data, data, chartScope)

  // filter bench data if longer history than strategy
  benchData = moreEq(benchData, formatDateISOString, filteredData[0].x)
  benchData = lessEq(benchData, formatDateISOString, filteredData[filteredData.length - 1].x)
  const cs = commonStart(filteredData, benchData)
  let bRatio = 1
  if (cs === undefined) {
    benchData = []
  } else {
    const bsv = eq(benchData, formatDateISOString, cs)[0].y
    const csv = eq(filteredData, formatDateISOString, cs)[0].y
    bRatio = bsv / csv

    benchData = benchData.map(
      d => {
        return {
          x: d.x,
          t: d.t,
          y: d.y * csv / bsv,
          cid: d.cid,
          cn: d.cn,
          cit: d.cit
        }
      })
  }

  const splitter = (d) => {
    const keys = [...new Set(d.map(d2 => d2.cid))]
    let vs = keys.map((d2) => {
      const ps = d.filter((v) => v.cid === d2)
      return { id: ps[0].cn + '|' + d2, data: ps }
    })
    vs = vs.sort((a, b) =>
      a.data[a.data.length - 1].t < b.data[b.data.length - 1].t ? 1 : -1)
    return vs
  }

  const allData =
        [
          { id: certInfo.benchmark_name, data: (showBenchmark ? chartAutoFilter(benchData, data, chartScope) : []) },
          ...splitter(filteredData)
        ]

  let totalTime, tickFormat
  const minTime = allData.map((d) => {
    if (d.data.length) {
      return d.data[d.data.length - 1].t
    } else {
      return undefined
    }
  }).reduce(function (rv, x) {
    if (rv === undefined) {
      return x
    }
    if (x === undefined) {
      return rv
    }
    if (x < rv) {
      return x
    }
    return rv
  }, undefined)

  const maxTime = allData.map((d) => {
    if (d.data.length) {
      return d.data[d.data.length - 1].t
    } else {
      return undefined
    }
  }).reduce(function (rv, x) {
    if (rv === undefined) {
      return x
    }
    if (x === undefined) {
      return rv
    }
    if (x > rv) {
      return x
    }
    return rv
  }, undefined)

  // handle case when month formatting is repeating itself in the x axis
  tickFormat = getRequiredMonthFormat
  if (maxTime !== undefined && minTime !== undefined) {
    totalTime = (maxTime.t - minTime.t) / (1000 * 3600 * 24 * 365.25)
    if (totalTime <= 1) {
      tickFormat = getRequiredDateFormat
    } else {
      tickFormat = getRequiredMonthFormat
    }
  }

  return (
    <span>
      <div className='card'>
        <div className='card-body' style={divStyle}>
          <h4 className='mt-0 header-title mb-4'>Track Record</h4>
          {benchData !== undefined && benchData.length !== 0
            ? <span
                onClick={toggleShowBenchmark}
                className='btn-chart'
              >
              {showBenchmark ? 'Hide Benchmark' : 'Show Benchmark'}
            </span>
            : <></>}

          <span>
            <span style={{ marginRight: '8px', marginLeft: '8px' }}>Range:</span>
            <span
              onClick={setChartScope1M}
              className={'btn-chart' + (chartScope === '1m' ? ' btn-chart-active' : '')}
            >1&nbsp;month
            </span>
            <span
              onClick={setChartScope3M}
              className={'btn-chart' + (chartScope === '3m' ? ' btn-chart-active' : '')}
            >3&nbsp;months
            </span>
            <span
              onClick={setChartScope6M}
              className={'btn-chart' + (chartScope === '6m' ? ' btn-chart-active' : '')}
            >6&nbsp;months
            </span>
            <span
              onClick={setChartScopeYTD}
              className={'btn-chart' + (chartScope === 'ytd' ? ' btn-chart-active' : '')}
            >ytd
            </span>
            <span
              onClick={setChartScope1Y}
              className={'btn-chart' + (chartScope === '1y' ? ' btn-chart-active' : '')}
            >1&nbsp;year
            </span>
            <span
              onClick={setChartScope2Y}
              className={'btn-chart' + (chartScope === '2y' ? ' btn-chart-active' : '')}
            >2&nbsp;years
            </span>
            <span
              onClick={setChartScopeAll}
              className={'btn-chart' + (chartScope === 'all' ? ' btn-chart-active' : '')}
            >max
            </span>
          </span>

          <span>
            <span style={{ marginRight: '8px', marginLeft: '8px' }}>Ymin:</span>
            <span
              onClick={toggleShowFrom0}
              className='btn-chart'
            >
              {showFrom0 ? 'auto' : '‎ ‎0‎ ‎'}
            </span>
          </span>

          <ResponsiveLine
            data={allData}
            colors={['rgba(34,34,34,0.34)', '#e51b23', '#0e86e7', 'rgba(14,134,231,0.7)', 'rgba(14,134,231,0.5)']}
            lineWidth={1.5}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              precision: 'day'
            }}
            yScale={{ type: 'linear', min: showFrom0 ? 0 : 'auto', max: 'auto', stacked: false, reverse: false }}
            yFormat=' >-.2f'
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              // tickSize: 5,
              tickCount: 5,
              // tickValues: 'every month',
              tickPadding: 5,
              tickRotation: 30,
              format: (d) => `${tickFormat(d)}`,
              opacity: 1
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            enablePoints={false}
              // gridXValues={ticks} // TODO automatically generate tick values
            pointSize={0}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            tooltip={
                  (x) => {
                    return (
                      <span>
                        <div>{x.point.serieId.split('|')[0]}</div>
                        <div>Date: <b>{getRequiredDateFormat(x.point.data.x)}</b></div>
                        <div>Type: <b>{x.point.data.cit}</b></div>
                        <div>{x.point.serieId === certInfo.benchmark_name ? 'Compared ' : ''}Performance: <b>{x.point.data.yFormatted}</b></div>
                        <div>{x.point.serieId === certInfo.benchmark_name ? 'Value: ' + formatNumber(x.point.data.y * bRatio) : ''}</div>
                      </span>
                    )
                  }
              }
            useMesh
          />

        </div>
        <div className='card-body'>
          <div style={{ float: 'left', paddingLeft: '60px' }}>
            {certInfo.six_currency !== undefined
              ? <span style={{ paddingRight: '26px' }}>Currency: <b>{certInfo.six_currency}</b></span>
              : <></>}
          </div>
          <div style={{ float: 'right', paddingRight: '50px' }} title={'Exchange: ' + certInfo.six_exchange}>
            <span style={{ marginRight: '26px', lineHeight: '32px', verticalAlign: 'text-top' }}>Data by</span>
            <img src='/assets/images/six.png' className='logo-lg' alt='' height='26' />
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <h4 className='mt-0 header-title mb-4'>Performance</h4>

          <ul className='list-inline widget-chart mt-4 mb-0 text-center'>

            <li className='list-inline-item'>
              <h5>{formatNumber(certInfo.last_price)}</h5>
              <p className='text-muted'>Latest Net Asset Value</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(certInfo.kpi_1year_perf)}</h5>
              <p className='text-muted'>1Y Performance</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(certInfo.kpi_ytd_perf)}{certInfo.kpi_ytd_perf_incomplete ? '**' : ''}</h5>
              <p className='text-muted'>YTD Performance</p>
            </li>

            <li className='list-inline-item'>
              <h5>{certInfo.last_price_date}</h5>
              <p className='text-muted'>Last update</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(certInfo.kpi_1year_vola)}</h5>
              <p className='text-muted'>1Y Volatility</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(certInfo.kpi_total_perf)}</h5>
              <p className='text-muted'>Total Performance</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(certInfo.kpi_annualized_returns)}</h5>
              <p className='text-muted'>Annualized Returns</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(bestMonth)}</h5>
              <p className='text-muted'>Best Month</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(worstMonth)}</h5>
              <p className='text-muted'>Worst Month</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatNumber(certInfo.kpi_1year_sharpe_perf)}</h5>
              <p className='text-muted'>1Y Sharpe</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatNumber(maxValue.y)}</h5>
              <p className='text-muted'>Max Net Asset Value</p>
            </li>

            <li className='list-inline-item'>
              <h5>{formatPercentage(maxDrawdown)}</h5>
              <p className='text-muted'>Max Drawdown</p>
            </li>

          </ul>
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <h4 className='mt-0 header-title mb-4'>Track Performance</h4>
          <div className='table-responsive'>
            <table className='table table-hover mb-0'>
              <thead>
                <tr>
                  <th>#</th>
                  {months.map((m, i) =>
                    <th key={i}>{m}</th>
                  )}
                  <th>Year</th>
                </tr>
              </thead>
              <tbody>
                {yearsList.map((y, yi) =>
                  <tr key={y}>
                    <th scope='row'>{y}</th>
                    {months.map((m, mi) =>
                      <td key={m}>{formatPercentage(trackMonthPerf[yi][mi]) + ((formatISOMonth(y, mi + 1) === lastYearMonth) ? '*' : (formatISOMonth(y, mi) === earliestAvailableYearMonth ? '**' : ''))}</td>
                    )}
                    <td>
                      <b>{formatPercentage(trackYearPerf[yi]) + (y === lastYear ? '*' : (y === earliestAvailableYear ? '**' : ''))}</b>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <p className='text-muted float-right'>* to-date calculation, ** from earliest date calculation</p>
        </div>
      </div>
    </span>
  )
}

export default CertificateTimeline
