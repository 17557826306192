import { combineReducers } from 'redux'
import {
  CERTS_LOADING,
  LOAD_CERTS_FAILURE,
  LOAD_CERTS_SUCCESS,
  CERT_INFO_LOADING,
  LOAD_CERT_INFO_FAILURE,
  LOAD_CERT_INFO_SUCCESS,
  CERT_CONTACT_LOADING,
  LOAD_CERT_CONTACT_FAILURE,
  LOAD_CERT_CONTACT_SUCCESS,
  BENCH_PERF_LOADING,
  LOAD_BENCH_PERF_FAILURE,
  LOAD_BENCH_PERF_SUCCESS,
  CERT_PERF_LOADING,
  LOAD_CERT_PERF_FAILURE,
  LOAD_CERT_PERF_SUCCESS,
  CERTS_PERF_LOADING,
  LOAD_CERTS_PERF_FAILURE,
  LOAD_CERTS_PERF_SUCCESS,
  LOAD_PROVIDERS_SUCCESS,
  LOAD_PROVIDERS_FAILURE,
  PROVIDERS_LOADING,
  LOAD_ISSUERS_SUCCESS,
  LOAD_ISSUERS_FAILURE,
  ISSUERS_LOADING,
  LOAD_TAGS_SUCCESS,
  LOAD_THEMES_SUCCESS,
  LOAD_INVESTMENTTYPES_SUCCESS,
  LOAD_COMPANYTYPES_SUCCESS,
  LOAD_STRATEGYSTYLES_SUCCESS,
  LOAD_STRATEGYTYPES_SUCCESS,
  LOAD_ASSETCLASSES_SUCCESS,
  PROVIDER_INFO_LOADING,
  LOAD_PROVIDER_INFO_SUCCESS,
  LOAD_PROVIDER_INFO_FAILURE,
  PROVIDER_CONTACT_LOADING,
  LOAD_PROVIDER_CONTACT_FAILURE,
  LOAD_PROVIDER_CONTACT_SUCCESS,
  ISSUER_INFO_LOADING,
  LOAD_ISSUER_INFO_SUCCESS,
  LOAD_ISSUER_INFO_FAILURE,
  PROVIDER_FILTER_REMOVED, PROVIDER_FILTER_SELECTED,
  TAG_FILTER_REMOVED, TAG_FILTER_SELECTED,
  THEME_FILTER_REMOVED, THEME_FILTER_SELECTED,
  INVESTMENTTYPE_FILTER_REMOVED, INVESTMENTTYPE_FILTER_SELECTED,
  STRATEGYSTYLE_FILTER_REMOVED, STRATEGYSTYLE_FILTER_SELECTED,
  STRATEGYTYPE_FILTER_REMOVED, STRATEGYTYPE_FILTER_SELECTED,
  ASSETCLASS_FILTER_REMOVED, ASSETCLASS_FILTER_SELECTED,
  LOAD_USER_SUCCESS, LOAD_USER_FAILURE, USER_LOADING,
  EDIT_USER_SUCCESS, EDIT_USER_FAILURE, USER_EDITING, CERT_COMPARE_SELECTED, CERT_COMPARE_REMOVED, CERT_COMPARE_TOGGLE, CERTS_COMPARE_SELECTED
} from '../actions/actions'
import { reducer as burgerMenu } from 'redux-burger-menu'

const contentInitialState = {
  isLoading: true,
  isFailed: false,
  isForbidden: false
}

const userEditInitialState = {
  isLoading: false,
  isFailed: false,
  overwrites: false,
  isForbidden: false,
  userInfo: { need_recertification: true }
}

const userInitialState = {
  ...contentInitialState,
  userInfo: {}
}

const contactInitialState = {
  ...contentInitialState
}

const certsInitialState = {
  ...contentInitialState,
  certs: []
}

const certInfoInitialState = {
  ...contentInitialState,
  certInfo: { tags: [] }
}

const certPerfInitialState = {
  ...contentInitialState,
  certPerf: []
}

const certsPerfInitialState = {
  ...contentInitialState,
  certsPerf: []
}

const benchPerfInitialState = {
  ...contentInitialState,
  benchPerf: []
}

const providerInfoInitialState = {
  ...contentInitialState,
  providerInfo: {}
}

const issuerInfoInitialState = {
  ...contentInitialState,
  issuerInfo: {}
}

const providerInitialState = {
  ...contentInitialState,
  providers: []
}

const issuerInitialState = {
  ...contentInitialState,
  issuers: []
}

const tagInitialState = {
  ...contentInitialState,
  tags: []
}

const themeInitialState = {
  ...contentInitialState,
  themes: []
}

const investmentTypeInitialState = {
  ...contentInitialState,
  investmentTypes: []
}

const companyTypeInitialState = {
  ...contentInitialState,
  companyTypes: []
}

const strategyStyleInitialState = {
  ...contentInitialState,
  strategyStyles: []
}

const strategyTypeInitialState = {
  ...contentInitialState,
  strategyTypes: []
}

const assetClassInitialState = {
  ...contentInitialState,
  assetClasses: []
}

const certFiltersInitialState = {
  ids: [],
  providers: [],
  issuers: [],
  tags: [],
  themes: [],
  investmentTypes: [],
  strategyStyles: [],
  strategyTypes: [],
  assetClasses: []
}

const certCompareInitialState = {
  ids: []
}

const user = (state = userInitialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_USER_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const userEdit = (state = userEditInitialState, action) => {
  switch (action.type) {
    case USER_EDITING:
      return {
        ...state,
        overwrites: false,
        isLoading: true
      }
    case EDIT_USER_FAILURE:
      return {
        ...state,
        overwrites: false,
        isLoading: false
      }
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        overwrites: true,
        userInfo: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const certs = (state = certsInitialState, action) => {
  switch (action.type) {
    case CERTS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_CERTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      }
    case LOAD_CERTS_SUCCESS:
      return {
        ...state,
        certs: action.payload.items,
        isLoading: false
      }
    default:
      return state
  }
}

const certInfo = (state = certInfoInitialState, action) => {
  switch (action.type) {
    case CERT_INFO_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_CERT_INFO_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_CERT_INFO_SUCCESS:
      return {
        ...state,
        certInfo: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const certContact = (state = contactInitialState, action) => {
  switch (action.type) {
    case CERT_CONTACT_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_CERT_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_CERT_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

const certPerf = (state = certPerfInitialState, action) => {
  switch (action.type) {
    case CERT_PERF_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_CERT_PERF_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_CERT_PERF_SUCCESS:
      return {
        ...state,
        certPerf: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const certsPerf = (state = certsPerfInitialState, action) => {
  switch (action.type) {
    case CERTS_PERF_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_CERTS_PERF_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_CERTS_PERF_SUCCESS:
      return {
        ...state,
        certsPerf: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const benchPerf = (state = benchPerfInitialState, action) => {
  switch (action.type) {
    case BENCH_PERF_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_BENCH_PERF_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_BENCH_PERF_SUCCESS:
      return {
        ...state,
        benchPerf: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const providerInfo = (state = providerInfoInitialState, action) => {
  switch (action.type) {
    case PROVIDER_INFO_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_PROVIDER_INFO_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_PROVIDER_INFO_SUCCESS:
      return {
        ...state,
        providerInfo: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const providerContact = (state = contactInitialState, action) => {
  switch (action.type) {
    case PROVIDER_CONTACT_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_PROVIDER_CONTACT_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_PROVIDER_CONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

const providers = (state = providerInitialState, action) => {
  switch (action.type) {
    case PROVIDERS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_PROVIDERS_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload.items,
        isLoading: false
      }
    default:
      return state
  }
}

const issuerInfo = (state = issuerInfoInitialState, action) => {
  switch (action.type) {
    case ISSUER_INFO_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_ISSUER_INFO_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_ISSUER_INFO_SUCCESS:
      return {
        ...state,
        issuerInfo: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}

const issuers = (state = issuerInitialState, action) => {
  switch (action.type) {
    case ISSUERS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case LOAD_ISSUERS_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case LOAD_ISSUERS_SUCCESS:
      return {
        ...state,
        issuers: action.payload.items,
        isLoading: false
      }
    default:
      return state
  }
}

const tags = (state = tagInitialState, action) => {
  switch (action.type) {
    case LOAD_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload.items
      }
    default:
      return state
  }
}

const themes = (state = themeInitialState, action) => {
  switch (action.type) {
    case LOAD_THEMES_SUCCESS:
      return {
        ...state,
        themes: action.payload.items
      }
    default:
      return state
  }
}

const investmentTypes = (state = investmentTypeInitialState, action) => {
  switch (action.type) {
    case LOAD_INVESTMENTTYPES_SUCCESS:
      return {
        ...state,
        investmentTypes: action.payload.items
      }
    default:
      return state
  }
}

const companyTypes = (state = companyTypeInitialState, action) => {
  switch (action.type) {
    case LOAD_COMPANYTYPES_SUCCESS:
      return {
        ...state,
        companyTypes: action.payload.items
      }
    default:
      return state
  }
}

const strategyStyles = (state = strategyStyleInitialState, action) => {
  switch (action.type) {
    case LOAD_STRATEGYSTYLES_SUCCESS:
      return {
        ...state,
        strategyStyles: action.payload.items
      }
    default:
      return state
  }
}

const strategyTypes = (state = strategyTypeInitialState, action) => {
  switch (action.type) {
    case LOAD_STRATEGYTYPES_SUCCESS:
      return {
        ...state,
        strategyTypes: action.payload.items
      }
    default:
      return state
  }
}

const assetClasses = (state = assetClassInitialState, action) => {
  switch (action.type) {
    case LOAD_ASSETCLASSES_SUCCESS:
      return {
        ...state,
        assetClasses: action.payload.items
      }
    default:
      return state
  }
}

const certCompare = (state = certCompareInitialState, action) => {
  switch (action.type) {
    case CERT_COMPARE_TOGGLE:
      if (state.ids.filter(item => item === action.payload.id).length === 0) {
        return {
          ...state,
          ids: [...state.ids, action.payload.id]
        }
      }
      return {
        ...state,
        ids: state.ids.filter(item => item !== action.payload.id)
      }
    case CERT_COMPARE_SELECTED:
      if (state.ids.filter(item => item === action.payload.id).length === 0) {
        return {
          ...state,
          ids: [...state.ids, action.payload.id]
        }
      }
      return state
    case CERTS_COMPARE_SELECTED:
      let ids = state.ids
      action.payload.ids.forEach((e) => {
        if (ids.filter(item => item === e).length === 0) {
          ids = [...ids, e]
        }
      })
      return {
        ...state,
        ids: ids
      }
    case CERT_COMPARE_REMOVED:
      return {
        ...state,
        ids: state.ids.filter(item => item !== action.payload.id)
      }
    default:
      return state
  }
}

const certFilters = (state = certFiltersInitialState, action) => {
  switch (action.type) {
    case PROVIDER_FILTER_SELECTED:
      if (state.providers.filter(item => item.id === action.payload.id).length === 0) {
        return {
          ...state,
          providers: [...state.providers, action.payload]
        }
      }
      return state
    case PROVIDER_FILTER_REMOVED:
      return {
        ...state,
        providers: state.providers.filter(item => item.id !== action.payload.providerId)
      }
    case TAG_FILTER_SELECTED:
      if (state.tags.filter(item => item.id === action.payload.id).length === 0) {
        return {
          ...state,
          tags: [...state.tags, action.payload]
        }
      }
      return state
    case TAG_FILTER_REMOVED:
      return {
        ...state,
        tags: state.tags.filter(item => item.id !== action.payload.tagId)
      }
    case THEME_FILTER_SELECTED:
      if (state.themes.filter(item => item.id === action.payload.id).length === 0) {
        return {
          ...state,
          themes: [...state.themes, action.payload]
        }
      }
      return state
    case THEME_FILTER_REMOVED:
      return {
        ...state,
        themes: state.themes.filter(item => item.id !== action.payload.themeId)
      }
    case INVESTMENTTYPE_FILTER_SELECTED:
      if (state.investmentTypes.filter(item => item.id === action.payload.id).length === 0) {
        return {
          ...state,
          investmentTypes: [...state.investmentTypes, action.payload]
        }
      }
      return state
    case INVESTMENTTYPE_FILTER_REMOVED:
      return {
        ...state,
        investmentTypes: state.investmentTypes.filter(item => item.id !== action.payload.investmentTypeId)
      }
    case STRATEGYSTYLE_FILTER_SELECTED:
      if (state.strategyStyles.filter(item => item.id === action.payload.id).length === 0) {
        return {
          ...state,
          strategyStyles: [...state.strategyStyles, action.payload]
        }
      }
      return state
    case STRATEGYSTYLE_FILTER_REMOVED:
      return {
        ...state,
        strategyStyles: state.strategyStyles.filter(item => item.id !== action.payload.strategyStyleId)
      }
    case STRATEGYTYPE_FILTER_SELECTED:
      if (state.strategyTypes.filter(item => item.id === action.payload.id).length === 0) {
        return {
          ...state,
          strategyTypes: [...state.strategyTypes, action.payload]
        }
      }
      return state
    case STRATEGYTYPE_FILTER_REMOVED:
      return {
        ...state,
        strategyTypes: state.strategyTypes.filter(item => item.id !== action.payload.strategyTypeId)
      }
    case ASSETCLASS_FILTER_SELECTED:
      if (state.assetClasses.filter(item => item.id === action.payload.id).length === 0) {
        return {
          ...state,
          assetClasses: [...state.assetClasses, action.payload]
        }
      }
      return state
    case ASSETCLASS_FILTER_REMOVED:
      return {
        ...state,
        assetClasses: state.assetClasses.filter(item => item.id !== action.payload.assetClassId)
      }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  user,
  userEdit,
  certs,
  certInfo,
  certContact,
  certPerf,
  certsPerf,
  certCompare,
  benchPerf,
  providerInfo,
  providerContact,
  providers,
  issuers,
  issuerInfo,
  tags,
  themes,
  investmentTypes,
  companyTypes,
  strategyStyles,
  strategyTypes,
  assetClasses,
  certFilters,
  burgerMenu
})

export default rootReducer
