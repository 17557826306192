import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadProviderInfo, requestProviderContact } from '../../actions/actionCreators'
import Loading from '../loading'
import ProviderCertificateList from '../ProviderCertificateList'
import Page from '../../Page'
import { cleanURL } from '../../Formats/Url'
import ModalContact from '../modal'
import Alert from '../alert'

const ProviderDetail = () => {
  const dispatch = useDispatch()
  const { providerId } = useParams()
  const providerInfo = useSelector(({ providerInfo }) => providerInfo.providerInfo, shallowEqual)
  const isLoading = useSelector(({ providerInfo }) => providerInfo.isLoading, shallowEqual)

  const [showModalContact, setShowModalContact] = useState(false)
  const [showModalContactSuccess, setShowModalContactSuccess] = useState(false)
  const [getModalContent, setModalContact] = useState('')
  const handleModalContactClose = () => setShowModalContact(false)
  const handleModalContactShow = () => setShowModalContact(true)

  useEffect(() => {
    dispatch(loadProviderInfo(providerId))
  }, [dispatch, providerId])

  const onContact = () => {
    setShowModalContact(false)
    setShowModalContactSuccess(true)
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowModalContactSuccess(false)
    }, 5000)
    dispatch(requestProviderContact(providerInfo.id, { body: getModalContent }))
  }

  function redirectURL (string) {
    if (string.substring(0, 4) !== 'http') {
      string = 'http://' + string
    }
    return string
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <Page title='myAMC.ch - Allocator'>

      <div className='header-bg'>
        <header id='topnav2'>

          <div className='container-fluid'>
            <div className='row align-items-center '>

              <div className='col-md-8'>
                <div className='page-title-box'>
                  <h3>{providerInfo.name}</h3>
                  {providerInfo.motto !== undefined
                    ? <h6>{providerInfo.motto}</h6>
                    : ''}
                  <p>
                    {providerInfo.description_long !== undefined
                      ? <div dangerouslySetInnerHTML={{ __html: providerInfo.description_long }} />
                      : (
                          providerInfo.description !== undefined
                            ? <>{providerInfo.description}</>
                            : ''
                        )}
                  </p>

                </div>
              </div>

              <div className='col-md-4'>
                <div className='d-md-block w-100' style={{ backgroundColor: providerInfo.logo_background }}>
                  {providerInfo.logo !== undefined
                    ? <img src={providerInfo.logo} alt={providerInfo.name + ' logo'} width='200' />
                    : ''}
                </div>
              </div>

            </div>

          </div>
        </header>
      </div>

      {(providerInfo.contact_email !== undefined || providerInfo.email !== undefined || providerInfo.website !== undefined || providerInfo.phone !== undefined)
        ? <div className='wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <h4 className='mt-0 header-title mb-4'>Contact Us</h4>

                    <div className='d-md-inline w-100 mb-4'>
                      <button
                        className='btn btn-primary waves-effect waves-light' type='button' data-toggle='collapse'
                        data-target='#collapseEmail' aria-expanded='false' aria-controls='collapseEmail'
                      >
                        Show Contact Info
                      </button>
                    </div>

                    {((providerInfo.contact_email !== undefined || providerInfo.email !== undefined) &&
                      <div className='d-md-inline w-100 mb-4 ml-3'>
                        <button
                          onClick={handleModalContactShow}
                          className='btn btn-primary waves-effect waves-light' type='button'
                        >
                          Get an introduction
                        </button>
                      </div>
                    )}

                    <ModalContact
                      show={showModalContact}
                      handleAction={onContact}
                      handleClose={handleModalContactClose}
                      title={'Get an introduction with ' + providerInfo.name}
                      contentChange={setModalContact}
                    >
                      <p>You can add a note to personalize your request for an introduction:</p>
                    </ModalContact>

                    <div className='d-block w-100 mt-2 h-25'>
                      <Alert
                        show={showModalContactSuccess}
                        severity='alert'
                      >
                        Message successfully sent
                      </Alert>
                    </div>

                    <div className='row align-items-center '>
                      <div className='col-md-8'>
                        <span className='collapse' id='collapseEmail'>
                          {providerInfo.website !== undefined
                            ? <ol className='breadcrumb'>
                              <a className='breadcrumb-item active' rel='noreferrer' href={redirectURL(providerInfo.website)} target='_blank'>{cleanURL(providerInfo.website)}</a>
                              </ol>
                            : ''}
                          {providerInfo.email !== undefined
                            ? <ol className='breadcrumb'>
                              <a className='breadcrumb-item active' href={'mailto:' + providerInfo.email}>{providerInfo.email}</a>
                              </ol>
                            : ''}
                          {providerInfo.phone !== undefined
                            ? <ol className='breadcrumb'>
                              <a className='breadcrumb-item active' href={'tel:' + providerInfo.phone}>{providerInfo.phone}</a>
                              </ol>
                            : ''}
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        : ''}

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <ProviderCertificateList />
            </div>
          </div>
        </div>
      </div>

    </Page>
  )
}

export default ProviderDetail
