export function formatNumber (d) {
  if (d === undefined || isNaN(d)) {
    return '-'
  }
  return d.toFixed(2)
}

export function format100Percentage (d) {
  if (d === undefined || isNaN(d)) {
    return '-'
  }
  return (d).toFixed(2) + '%'
}

export function formatPercentage (d) {
  if (d === undefined) {
    return '-'
  }
  return (d * 100).toFixed(2) + '%'
}
