const dev = {
  /*  oauth0: {
    DOMAIN: 'dev-nmerkulov-3nity-cap.eu.auth0.com',
    CLIENTID: 'TXyu0M05KHRNTpZdffKejxNqIcsnK7Na'
  }, */
  oauth0: {
    DOMAIN: 'login.myamc.ch',
    CLIENTID: 'qPvJWiwK1Se5jSvKvgDELrr0bMLjruyJ'
  },
  api: {
    ROOT: '/api'
  }
}

const prod = {
  oauth0: {
    DOMAIN: 'login.myamc.ch',
    CLIENTID: 'qPvJWiwK1Se5jSvKvgDELrr0bMLjruyJ'
  },
  api: {
    ROOT: 'https://api.myamc.ch/api'
  }
}

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev

process.platform = 'darwin'

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}
