import React from 'react'
import ProfileForm from '../ProfileForm'
import Page from '../../Page'

export default function PageProfile () {
  return (
    <Page title='myAMC.ch - Profile'>

      <div className='header-bg'>
        <header id='topnav2'>
          <div className='container-fluid'>
            <div className='row align-items-center '>
              <div className='col-md-8'>
                <h6><i className='dripicons-user mr-2' />Your Profile</h6>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <ProfileForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Page>
  )
}
