import React, { useEffect, useState } from 'react'
import Page from '../../Page'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { formatCamelCase } from '../../Formats/String'
import { loadCerts } from '../../actions/actionCreators'
import Loading from '../loading'
import { formatNumber, formatPercentage } from '../../Formats/Number'

const PageAssetClass = () => {
  const dispatch = useDispatch()
  const { assetClassName } = useParams()
  const { certs, isLoading } = useSelector(({ certs }) => certs, shallowEqual)
  const { userInfo, _ } = useSelector(({ user }) => user, shallowEqual)

  let assetClassID
  switch (assetClassName.toLowerCase()) {
    case 'equity':
      assetClassID = 1
      break
    case 'fixed income':
      assetClassID = 2
      break
    case 'commodity':
      assetClassID = 3
      break
    case 'gold':
      assetClassID = 4
      break
    case 'multi assets':
      assetClassID = 5
      break
    case 'crypto':
      assetClassID = 6
      break
    case 'private equity':
      assetClassID = 7
      break
  }
  const certFilters = {
    ids: [],
    providers: [],
    issuers: [],
    tags: [],
    themes: [],
    investmentTypes: [],
    strategyStyles: [],
    strategyTypes: [],
    assetClasses: [{ id: assetClassID }]
  }

  const [sortBy, setSortBy] = useState('1y_perf')
  const setSortBy1dPerf = () => setSortBy('1d_perf')
  const setSortBy1mPerf = () => setSortBy('1m_perf')
  const setSortBy3mPerf = () => setSortBy('3m_perf')
  const setSortByYtdPerf = () => setSortBy('ytd_perf')
  const setSortBy1yPerf = () => setSortBy('1y_perf')
  const setSortBy3yPerf = () => setSortBy('3y_perf')
  const setSortBy1ySharpe = () => setSortBy('1y_sharpe')

  useEffect(() => {
    dispatch(loadCerts(certFilters, sortBy, 5000))
  }, [assetClassName, dispatch, sortBy])

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, [])
  }

  console.log(certs)
  const groupedCertificate = groupBy(certs, 'strategy_type_name')

  if (isLoading) {
    return <Loading />
  }

  return (
    <Page title={'myAMC.ch - ' + formatCamelCase(assetClassName)}>

      <div className='header-bg'>
        <header id='topnav2'>
          <div className='container-fluid'>
            <div className='row align-items-center '>
              <div className='col-md-8'>
                <h6><i className='dripicons-search mr-2' />{formatCamelCase(assetClassName)}</h6>
                <p>Browse through all our certificates</p>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className='mt-0 header-title mb-4'>Our Strategies</h4>

                  <table
                    className='table table-striped table-bordered dt-responsive nowrap'
                    style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}
                  >
                    <thead>
                      <tr className='th-is-sticky'>
                        <th />
                        <>{(userInfo.id === 'anonymous')
                          ? <></>
                          : <th>Last Nav</th>}
                        </>
                        <th
                          className={'btn-chart' + (sortBy === '1d_perf' ? ' btn-chart-active' : '')}
                          onClick={setSortBy1dPerf}
                        >
                          1d Perf
                        </th>
                        <th
                          className={'btn-chart' + (sortBy === '1m_perf' ? ' btn-chart-active' : '')}
                          onClick={setSortBy1mPerf}
                        >
                          1m Perf
                        </th>
                        <th
                          className={'btn-chart' + (sortBy === '3m_perf' ? ' btn-chart-active' : '')}
                          onClick={setSortBy3mPerf}
                        >
                          3m Perf
                        </th>
                        <th
                          className={'btn-chart' + (sortBy === 'ytd_perf' ? ' btn-chart-active' : '')}
                          onClick={setSortByYtdPerf}
                        >
                          ytd Perf
                        </th>
                        <th
                          className={'btn-chart' + (sortBy === '1y_perf' ? ' btn-chart-active' : '')}
                          onClick={setSortBy1yPerf}
                        >
                          1y Perf
                        </th>
                        <th
                          className={'btn-chart' + (sortBy === '3y_perf' ? ' btn-chart-active' : '')}
                          onClick={setSortBy3yPerf}
                        >
                          3y Perf
                        </th>
                        <th
                          className={'btn-chart' + (sortBy === '1y_sharpe' ? ' btn-chart-active' : '')}
                          onClick={setSortBy1ySharpe}
                        >
                          1y Sharpe
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(groupedCertificate).map((assetclass, j) => (
                        <>
                          <tr key={'class' + j}>
                            <td>
                              <h5>{assetclass}</h5>
                            </td>
                            <>{(userInfo.id === 'anonymous')
                              ? <></>
                              : <td> </td>}
                            </>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                          </tr>
                          {groupedCertificate[assetclass].map((item, i) => (
                            <tr key={i}>
                              <td>
                                <Link to={'/certificate/' + item.id}>{item.name}</Link>
                                <div style={{ fontStyle: 'italic', color: '#888' }}>
                                  @{item.provider.name}
                                </div>
                              </td>
                              <>{(userInfo.id === 'anonymous')
                                ? <></>
                                : <td>{formatNumber(item.last_price)}</td>}
                              </>
                              <td>{formatPercentage(item.kpi_1day_perf)}</td>
                              <td>{formatPercentage(item.kpi_1month_perf)}</td>
                              <td>{formatPercentage(item.kpi_3months_perf)}</td>
                              <td>{formatPercentage(item.kpi_ytd_perf)}</td>
                              <td>{formatPercentage(item.kpi_1year_perf)}</td>
                              <td>{formatPercentage(item.kpi_3years_perf)}</td>
                              <td>{formatNumber(item.kpi_1year_sharpe_perf)}</td>
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Page>
  )
}

export default PageAssetClass
