import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadUser } from '../actions/actionCreators'
import { useAuth0 } from '@auth0/auth0-react'

function validateUser (user, userEdit, path, isLoading) {
  const path1 = path.split('/')[1]
  if (!isLoading && path !== '/profile' &&
      user.need_recertification && userEdit.need_recertification) {
    window.location.href = '/profile'
  }
  if (user.id === 'anonymous' && path !== '/' && path !== '/explore' && path1 !== 'asset-class') {
    window.location.href = '/'
  }
}

const PageViews = () => {
  const location = useLocation()

  const { loginWithRedirect } = useAuth0()

  const { userInfo, isLoading } = useSelector(({ user }) => user, shallowEqual)
  const userEdit = useSelector(({ userEdit }) => userEdit, shallowEqual)
  validateUser(userInfo, userEdit.userInfo, location.pathname, isLoading)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadUser())
  }, [dispatch])

  useEffect(
    () => {
      validateUser(userInfo, userEdit.userInfo, location.pathname, isLoading)
    },
    [location]
  )
  if (userInfo.id === 'anonymous') {
    return (
      <div className='alert alert-danger mt-2' role='alert'>
        <h6 className='mt-4'>Your current access is limited to Dashboard and Explore features !</h6>
        <p className='mt-3'>Please <button className='btn btn-primary' onClick={() => loginWithRedirect()}>Log In or Sign Up</button> to remove all restrictions. myAMC.ch is completely <strong>free for investors</strong>. No credit card nor payment will ever be required to access this site.</p>
      </div>
    )
  }
  return <></>
}

export default PageViews
