import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadProviders } from '../../../../actions/actionCreators'
import ReactHTMLDatalist from 'react-html-datalist'
import { selectProviderFilter } from '../../../../actions/actions'

export default function ProvidersSelect () {
  const [name, setName] = useState('')

  const dispatch = useDispatch()
  const providers = useSelector(({ providers }) => providers.providers, shallowEqual)

  const newOption = provider => {
    return {
      text: provider.name, value: provider.id
    }
  }
  const newProvider = option => {
    return {
      name: option.text, id: option.value
    }
  }

  useEffect(() => {
    dispatch(loadProviders(name, 'name'))
  }, [dispatch, name])

  const handleChange = e => {
    if (e.target.value === '') {
      setName(e.target.text)
      return
    }
    dispatch(selectProviderFilter(newProvider(e.target)))
  }
  return (
    <>
      <label htmlFor='provider-input' className='col-md-1 col-form-label'>Allocator</label>
      <div className='col-md-3'>
        <ReactHTMLDatalist
          name='provider_id'
          onChange={handleChange}
          classNames='form-control'
          options={providers.map(newOption)}
        />
      </div>
    </>
  )
}
