import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadStrategyStyles } from '../../../../actions/actionCreators'
import ReactHTMLDatalist from 'react-html-datalist'
import { selectStrategyStyleFilter } from '../../../../actions/actions'

export default function StrategyStylesSelect () {
  const [name, setName] = useState('')

  const dispatch = useDispatch()
  const strategyStyles = useSelector(({ strategyStyles }) => strategyStyles.strategyStyles, shallowEqual)

  const newOption = strategyStyle => {
    return {
      text: strategyStyle.name, value: strategyStyle.id
    }
  }
  const newStrategyStyle = option => {
    return {
      name: option.text, id: option.value
    }
  }

  useEffect(() => {
    dispatch(loadStrategyStyles(name))
  }, [dispatch, name])

  const handleChange = e => {
    if (e.target.value === '') {
      setName(e.target.text)
      return
    }
    dispatch(selectStrategyStyleFilter(newStrategyStyle(e.target)))
  }
  return (
    <>
      <label htmlFor='strategyStyle-input' className='col-md-1 col-form-label'>Strat. Style</label>
      <div className='col-md-3'>
        <ReactHTMLDatalist
          name='strategyStyle_id'
          onChange={handleChange}
          classNames='form-control'
          options={strategyStyles.map(newOption)}
        />
      </div>
    </>
  )
}
