import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadCerts } from '../../actions/actionCreators'
import Loading from '../loading'
import { Link } from 'react-router-dom'
import { formatNumber, formatPercentage } from '../../Formats/Number'
import ProvidersSelect from './Filter/Provider/select'
import ProvidersFilters from './Filter/Provider/filter'
import AssetClassesSelect from './Filter/AssetClass/select'
import AssetClassesFilters from './Filter/AssetClass/filter'
import TagsSelect from './Filter/Tag/select'
import TagsFilters from './Filter/Tag/filter'
import InvestmentTypesSelect from './Filter/InvestmentType/select'
import InvestmentTypesFilters from './Filter/InvestmentType/filter'
import ThemesSelect from './Filter/Theme/select'
import ThemesFilters from './Filter/Theme/filter'
import StrategyTypesSelect from './Filter/StrategyType/select'
import StrategyTypesFilters from './Filter/StrategyType/filter'
import StrategyStylesSelect from './Filter/StrategyStyle/select'
import StrategyStylesFilters from './Filter/StrategyStyle/filter'
import { formatBadgeText } from '../../Formats/Badge'
import { formatCaret } from '../../Formats/Caret'
import { toggleCertificateCompare, selectCertificatesCompare } from '../../actions/actions'

const CertificateList = () => {
  const dispatch = useDispatch()
  const certs = useSelector(({ certs }) => certs.certs, shallowEqual)
  const isLoading = useSelector(({ certs }) => certs.isLoading, shallowEqual)
  const certFilters = useSelector(({ certFilters }) => certFilters, shallowEqual)
  const certCompare = useSelector(({ certCompare }) => certCompare, shallowEqual)
  const { userInfo, _ } = useSelector(({ user }) => user, shallowEqual)

  const [sortBy, setSortBy] = useState('1y_perf')
  const setSortBy1dPerf = () => setSortBy('1d_perf')
  const setSortBy1mPerf = () => setSortBy('1m_perf')
  const setSortBy3mPerf = () => setSortBy('3m_perf')
  const setSortByYtdPerf = () => setSortBy('ytd_perf')
  const setSortBy1yPerf = () => setSortBy('1y_perf')
  const setSortBy3yPerf = () => setSortBy('3y_perf')
  const setSortBy1ySharpe = () => setSortBy('1y_sharpe')

  useEffect(() => {
    dispatch(loadCerts(certFilters, sortBy, 5000))
  }, [dispatch, certFilters, sortBy])

  const setCompare = (d) => {
    dispatch(toggleCertificateCompare(d))
  }

  const setCompareAll = () => {
    dispatch(selectCertificatesCompare(certs.map((d) => (d.id))))
  }

  const isSelected = (id) => {
    return (certCompare.ids.filter(item => item === id).length === 0) ? <i className='dripicons-pin' /> : <i className='dripicons-trash' />
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      {(userInfo.id === 'anonymous')
        ? <div className='alert alert-danger mt-2' role='alert'>
          Filter bar disabled
        </div>
        : <div className='form-group row'>
          <ProvidersSelect />
          <ProvidersFilters />
          <AssetClassesSelect />
          <AssetClassesFilters />
          <TagsSelect />
          <TagsFilters />
          <ThemesSelect />
          <ThemesFilters />
          <StrategyTypesSelect />
          <StrategyTypesFilters />
          <StrategyStylesSelect />
          <StrategyStylesFilters />
          <InvestmentTypesSelect />
          <InvestmentTypesFilters />
          </div>}
      <table
        className='table table-striped table-bordered dt-responsive nowrap'
        style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}
      >
        <thead>
          <tr className='th-is-sticky'>
            <th>Name</th>
            {(userInfo.id === 'anonymous')
              ? <></>
              : <th>Last Nav</th>}
            <th><small>Last Nav Date</small></th>
            <th
              className={'btn-chart' + (sortBy === '1d_perf' ? ' btn-chart-active' : '')}
              onClick={setSortBy1dPerf}
            >
              1d Perf
            </th>
            <th
              className={'btn-chart' + (sortBy === '1m_perf' ? ' btn-chart-active' : '')}
              onClick={setSortBy1mPerf}
            >
              1m Perf
            </th>
            <th
              className={'btn-chart' + (sortBy === '3m_perf' ? ' btn-chart-active' : '')}
              onClick={setSortBy3mPerf}
            >
              3m Perf
            </th>
            <th
              className={'btn-chart' + (sortBy === 'ytd_perf' ? ' btn-chart-active' : '')}
              onClick={setSortByYtdPerf}
            >
              ytd Perf
            </th>
            <th
              className={'btn-chart' + (sortBy === '1y_perf' ? ' btn-chart-active' : '')}
              onClick={setSortBy1yPerf}
            >
              1y Perf
            </th>
            <th
              className={'btn-chart' + (sortBy === '3y_perf' ? ' btn-chart-active' : '')}
              onClick={setSortBy3yPerf}
            >
              3y Perf
            </th>
            <th
              className={'btn-chart' + (sortBy === '1y_sharpe' ? ' btn-chart-active' : '')}
              onClick={setSortBy1ySharpe}
            >
              1y Sharpe
            </th>

            {(userInfo.id === 'anonymous')
              ? <></>
              : <>
                {certCompare.ids.length > 0
                  ? (<th className='btn-primary-inline'>
                    <Link to='/compare'>
                      Compare
                    </Link>
                     </th>)
                  : (
                    <th style={{ color: '#656565' }} onClick={() => setCompareAll()}>Select All</th>
                    )}
                </>}
          </tr>
        </thead>
        <tbody>
          {certs.map((item, i) => (
            <tr key={i}>
              <td>
                <div>
                  <Link to={'certificate/' + item.id}>{item.name}</Link>
                  <span className='float-right'>
                    <span className='ml-1 badge badge-primary badge-within' title={item.subscription_frequency_name}>{formatBadgeText(item.subscription_frequency_name)}</span>
                    <span className='ml-1 badge badge-info badge-within' title={item.strategy_type_name}>{formatBadgeText(item.strategy_type_name)}</span>
                    <span className='ml-1 badge badge-secondary badge-within' title={item.asset_class_name}>{formatBadgeText(item.asset_class_name)}</span>
                  </span>
                </div>
                <div style={{ fontStyle: 'italic', color: '#888' }}>
                  @{item.provider.name}
                </div>
              </td>
              {(userInfo.id === 'anonymous')
                ? <></>
                : <td>{formatNumber(item.last_price)}</td>}
              <td>
                {item.last_price_date !== '0001-01-01'
                  ? <small> {item.last_price_date}</small>
                  : ''}
              </td>
              <td>{formatPercentage(item.kpi_1day_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_1day_perf)}</span></td>
              <td>{formatPercentage(item.kpi_1month_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_1month_perf)}</span></td>
              <td>{formatPercentage(item.kpi_3months_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_3months_perf)}</span></td>
              <td>{formatPercentage(item.kpi_ytd_perf)}{item.kpi_ytd_perf_incomplete ? '**' : ''}<span style={{ float: 'right' }}>{formatCaret(item.kpi_ytd_perf)}</span></td>
              <td>{formatPercentage(item.kpi_1year_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_1year_perf)}</span></td>
              <td>{formatPercentage(item.kpi_3years_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_3years_perf)}</span></td>
              <td>{formatNumber(item.kpi_1year_sharpe_perf)}</td>
              {(userInfo.id === 'anonymous')
                ? <></>
                : <td onClick={() => setCompare(item.id)}>{isSelected(item.id)}</td>}
            </tr>
          ))}
        </tbody>
      </table>
      <p className='text-muted float-right'>** from earliest date calculation</p>
    </>
  )
}

export default CertificateList
