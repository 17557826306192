export const LOAD_CERTS_SUCCESS = 'LOAD_CERTS_SUCCESS'
export const LOAD_CERTS_FAILURE = 'LOAD_CERTS_FAILURE'

export const LOAD_CERT_INFO_SUCCESS = 'LOAD_CERT_INFO_SUCCESS'
export const LOAD_CERT_INFO_FAILURE = 'LOAD_CERT_INFO_FAILURE'

export const LOAD_CERT_CONTACT_SUCCESS = 'LOAD_CERT_CONTACT_SUCCESS'
export const LOAD_CERT_CONTACT_FAILURE = 'LOAD_CERT_CONTACT_FAILURE'

export const LOAD_CERT_PERF_SUCCESS = 'LOAD_CERT_PERF_SUCCESS'
export const LOAD_CERT_PERF_FAILURE = 'LOAD_CERT_PERF_FAILURE'
export const LOAD_CERTS_PERF_SUCCESS = 'LOAD_CERTS_PERF_SUCCESS'
export const LOAD_CERTS_PERF_FAILURE = 'LOAD_CERTS_PERF_FAILURE'

export const LOAD_BENCH_PERF_SUCCESS = 'LOAD_BENCH_PERF_SUCCESS'
export const LOAD_BENCH_PERF_FAILURE = 'LOAD_BENCH_PERF_FAILURE'

export const LOAD_PROVIDER_INFO_SUCCESS = 'LOAD_PROVIDER_INFO_SUCCESS'
export const LOAD_PROVIDER_INFO_FAILURE = 'LOAD_PROVIDER_INFO_FAILURE'

export const LOAD_PROVIDER_CONTACT_SUCCESS = 'LOAD_PROVIDER_CONTACT_SUCCESS'
export const LOAD_PROVIDER_CONTACT_FAILURE = 'LOAD_PROVIDER_CONTACT_FAILURE'

export const LOAD_PROVIDERS_SUCCESS = 'LOAD_PROVIDERS_SUCCESS'
export const LOAD_PROVIDERS_FAILURE = 'LOAD_PROVIDERS_FAILURE'
export const PROVIDER_FILTER_SELECTED = 'PROVIDER_FILTER_SELECTED'
export const PROVIDER_FILTER_REMOVED = 'PROVIDER_FILTER_REMOVED'

export const CERT_COMPARE_SELECTED = 'CERT_COMPARE_SELECTED'
export const CERTS_COMPARE_SELECTED = 'CERTS_COMPARE_SELECTED'
export const CERT_COMPARE_REMOVED = 'CERT_COMPARE_REMOVED'
export const CERT_COMPARE_TOGGLE = 'CERT_COMPARE_TOGGLE'

export const LOAD_ISSUER_INFO_SUCCESS = 'LOAD_ISSUER_INFO_SUCCESS'
export const LOAD_ISSUER_INFO_FAILURE = 'LOAD_ISSUER_INFO_FAILURE'

export const LOAD_ISSUERS_SUCCESS = 'LOAD_ISSUERS_SUCCESS'
export const LOAD_ISSUERS_FAILURE = 'LOAD_ISSUERS_FAILURE'

export const LOAD_TAGS_SUCCESS = 'LOAD_TAGS_SUCCESS'
export const LOAD_THEMES_SUCCESS = 'LOAD_THEMES_SUCCESS'
export const LOAD_INVESTMENTTYPES_SUCCESS = 'LOAD_INVESTMENTTYPES_SUCCESS'
export const LOAD_COMPANYTYPES_SUCCESS = 'LOAD_COMPANYTYPES_SUCCESS'
export const LOAD_STRATEGYSTYLES_SUCCESS = 'LOAD_STRATEGYSTYLES_SUCCESS'
export const LOAD_STRATEGYTYPES_SUCCESS = 'LOAD_STRATEGYTYPES_SUCCESS'
export const LOAD_ASSETCLASSES_SUCCESS = 'LOAD_ASSETCLASSES_SUCCESS'
export const LOAD_TAGS_FAILURE = 'LOAD_TAGS_FAILURE'
export const LOAD_THEMES_FAILURE = 'LOAD_THEMES_FAILURE'
export const LOAD_INVESTMENTTYPES_FAILURE = 'LOAD_INVESTMENTTYPES_FAILURE'
export const LOAD_COMPANYTYPES_FAILURE = 'LOAD_COMPANYTYPES_FAILURE'
export const LOAD_STRATEGYSTYLES_FAILURE = 'LOAD_STRATEGYSTYLES_FAILURE'
export const LOAD_STRATEGYTYPES_FAILURE = 'LOAD_STRATEGYTYPES_FAILURE'
export const LOAD_ASSETCLASSES_FAILURE = 'LOAD_ASSETCLASSES_FAILURE'
export const TAG_FILTER_SELECTED = 'TAG_FILTER_SELECTED'
export const TAG_FILTER_REMOVED = 'TAG_FILTER_REMOVED'
export const THEME_FILTER_SELECTED = 'THEME_FILTER_SELECTED'
export const THEME_FILTER_REMOVED = 'THEME_FILTER_REMOVED'
export const INVESTMENTTYPE_FILTER_SELECTED = 'INVESTMENTTYPE_FILTER_SELECTED'
export const INVESTMENTTYPE_FILTER_REMOVED = 'INVESTMENTTYPE_FILTER_REMOVED'
export const STRATEGYSTYLE_FILTER_SELECTED = 'STRATEGYSTYLE_FILTER_SELECTED'
export const STRATEGYSTYLE_FILTER_REMOVED = 'STRATEGYSTYLE_FILTER_REMOVED'
export const STRATEGYTYPE_FILTER_SELECTED = 'STRATEGYTYPE_FILTER_SELECTED'
export const STRATEGYTYPE_FILTER_REMOVED = 'STRATEGYTYPE_FILTER_REMOVED'
export const ASSETCLASS_FILTER_SELECTED = 'ASSETCLASS_FILTER_SELECTED'
export const ASSETCLASS_FILTER_REMOVED = 'ASSETCLASS_FILTER_REMOVED'

export const CERTS_LOADING = 'CERTS_LOADING'
export const CERT_INFO_LOADING = 'CERT_INFO_LOADING'
export const CERT_CONTACT_LOADING = 'CERT_CONTACT_LOADING'
export const CERT_PERF_LOADING = 'CERT_PERF_LOADING'
export const CERTS_PERF_LOADING = 'CERTS_PERF_LOADING'
export const BENCH_PERF_LOADING = 'BENCH_PERF_LOADING'
export const PROVIDER_INFO_LOADING = 'PROVIDER_INFO_LOADING'
export const PROVIDER_CONTACT_LOADING = 'PROVIDER_CONTACT_LOADING'
export const PROVIDERS_LOADING = 'PROVIDERS_LOADING'
export const ISSUER_INFO_LOADING = 'ISSUER_INFO_LOADING'
export const ISSUERS_LOADING = 'ISSUERS_LOADING'

export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE'
export const USER_LOADING = 'USER_LOADING'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'
export const USER_EDITING = 'USER_EDITING'

export function editUserSuccess (data) {
  return {
    type: EDIT_USER_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function editUserFailure (error) {
  return {
    type: EDIT_USER_FAILURE,
    payload: {
      error: error
    }
  }
}

export function userEditing () {
  return {
    type: USER_EDITING
  }
}

export function loadUserSuccess (data) {
  return {
    type: LOAD_USER_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadUserFailure (error) {
  return {
    type: LOAD_USER_FAILURE,
    payload: {
      error: error
    }
  }
}

export function userLoading () {
  return {
    type: USER_LOADING
  }
}

export function loadCertsSuccess (data) {
  return {
    type: LOAD_CERTS_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadCertsFailure (error) {
  return {
    type: LOAD_CERTS_FAILURE,
    payload: {
      error: error
    }
  }
}

export function certsLoading () {
  return {
    type: CERTS_LOADING
  }
}

export function loadCertInfoSuccess (data) {
  return {
    type: LOAD_CERT_INFO_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadCertInfoFailure (error) {
  return {
    type: LOAD_CERT_INFO_FAILURE,
    payload: {
      error: error
    }
  }
}

export function certInfoLoading () {
  return {
    type: CERT_INFO_LOADING
  }
}

export function loadCertContactSuccess (data) {
  return {
    type: LOAD_CERT_CONTACT_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadCertContactFailure (error) {
  return {
    type: LOAD_CERT_CONTACT_FAILURE,
    payload: {
      error: error
    }
  }
}

export function certContactLoading () {
  return {
    type: CERT_CONTACT_LOADING
  }
}

export function loadCertPerfSuccess (data) {
  return {
    type: LOAD_CERT_PERF_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadCertPerfFailure (error) {
  return {
    type: LOAD_CERT_PERF_FAILURE,
    payload: {
      error: error
    }
  }
}

export function certPerfLoading () {
  return {
    type: CERT_PERF_LOADING
  }
}

export function loadCertsPerfSuccess (data) {
  return {
    type: LOAD_CERTS_PERF_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadCertsPerfFailure (error) {
  return {
    type: LOAD_CERTS_PERF_FAILURE,
    payload: {
      error: error
    }
  }
}

export function certsPerfLoading () {
  return {
    type: CERTS_PERF_LOADING
  }
}

export function loadBenchPerfSuccess (data) {
  return {
    type: LOAD_BENCH_PERF_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadBenchPerfFailure (error) {
  return {
    type: LOAD_BENCH_PERF_FAILURE,
    payload: {
      error: error
    }
  }
}

export function benchPerfLoading () {
  return {
    type: BENCH_PERF_LOADING
  }
}

export function loadIssuersSuccess (data) {
  return {
    type: LOAD_ISSUERS_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadIssuersFailure (error) {
  return {
    type: LOAD_ISSUERS_FAILURE,
    payload: {
      error: error
    }
  }
}

export function loadProvidersSuccess (data) {
  return {
    type: LOAD_PROVIDERS_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadProvidersFailure (error) {
  return {
    type: LOAD_PROVIDERS_FAILURE,
    payload: {
      error: error
    }
  }
}

export function toggleCertificateCompare (id) {
  return {
    type: CERT_COMPARE_TOGGLE,
    payload: {
      id
    }
  }
}

export function selectCertificateCompare (id) {
  return {
    type: CERT_COMPARE_SELECTED,
    payload: {
      id
    }
  }
}

export function selectCertificatesCompare (ids) {
  return {
    type: CERTS_COMPARE_SELECTED,
    payload: {
      ids
    }
  }
}

export function removeCertificateCompare (id) {
  return {
    type: CERT_COMPARE_REMOVED,
    payload: {
      id
    }
  }
}

export function selectProviderFilter (provider) {
  return {
    type: PROVIDER_FILTER_SELECTED,
    payload: {
      ...provider
    }
  }
}

export function removeProviderFilter (providerId) {
  return {
    type: PROVIDER_FILTER_REMOVED,
    payload: {
      providerId: providerId
    }
  }
}

export function loadProviderInfoSuccess (data) {
  return {
    type: LOAD_PROVIDER_INFO_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadProviderInfoFailure (error) {
  return {
    type: LOAD_PROVIDER_INFO_FAILURE,
    payload: {
      error: error
    }
  }
}

export function providerInfoLoading () {
  return {
    type: PROVIDER_INFO_LOADING
  }
}

export function loadProviderContactSuccess (data) {
  return {
    type: LOAD_PROVIDER_CONTACT_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadProviderContactFailure (error) {
  return {
    type: LOAD_PROVIDER_CONTACT_FAILURE,
    payload: {
      error: error
    }
  }
}

export function providerContactLoading () {
  return {
    type: PROVIDER_CONTACT_LOADING
  }
}

export function providersLoading () {
  return {
    type: PROVIDERS_LOADING
  }
}

export function loadIssuerInfoSuccess (data) {
  return {
    type: LOAD_ISSUER_INFO_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadIssuerInfoFailure (error) {
  return {
    type: LOAD_ISSUER_INFO_FAILURE,
    payload: {
      error: error
    }
  }
}

export function issuerInfoLoading () {
  return {
    type: ISSUER_INFO_LOADING
  }
}

export function issuersLoading () {
  return {
    type: ISSUERS_LOADING
  }
}

export function loadTagsSuccess (data) {
  return {
    type: LOAD_TAGS_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadTagsFailure (error) {
  return {
    type: LOAD_TAGS_FAILURE,
    payload: {
      error: error
    }
  }
}

export function selectTagFilter (tag) {
  return {
    type: TAG_FILTER_SELECTED,
    payload: {
      ...tag
    }
  }
}

export function removeTagFilter (tagId) {
  return {
    type: TAG_FILTER_REMOVED,
    payload: {
      tagId: tagId
    }
  }
}

export function loadThemesSuccess (data) {
  return {
    type: LOAD_THEMES_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadThemesFailure (error) {
  return {
    type: LOAD_THEMES_FAILURE,
    payload: {
      error: error
    }
  }
}

export function selectThemeFilter (theme) {
  return {
    type: THEME_FILTER_SELECTED,
    payload: {
      ...theme
    }
  }
}

export function removeThemeFilter (themeId) {
  return {
    type: THEME_FILTER_REMOVED,
    payload: {
      themeId: themeId
    }
  }
}

export function loadInvestmentTypesSuccess (data) {
  return {
    type: LOAD_INVESTMENTTYPES_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadInvestmentTypesFailure (error) {
  return {
    type: LOAD_INVESTMENTTYPES_FAILURE,
    payload: {
      error: error
    }
  }
}

export function loadCompanyTypesSuccess (data) {
  return {
    type: LOAD_COMPANYTYPES_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadCompanyTypesFailure (error) {
  return {
    type: LOAD_COMPANYTYPES_FAILURE,
    payload: {
      error: error
    }
  }
}

export function selectInvestmentTypeFilter (investmentType) {
  return {
    type: INVESTMENTTYPE_FILTER_SELECTED,
    payload: {
      ...investmentType
    }
  }
}

export function removeInvestmentTypeFilter (investmentTypeId) {
  return {
    type: INVESTMENTTYPE_FILTER_REMOVED,
    payload: {
      investmentTypeId: investmentTypeId
    }
  }
}

export function loadStrategyStylesSuccess (data) {
  return {
    type: LOAD_STRATEGYSTYLES_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadStrategyStylesFailure (error) {
  return {
    type: LOAD_STRATEGYSTYLES_FAILURE,
    payload: {
      error: error
    }
  }
}

export function selectStrategyStyleFilter (strategyStyle) {
  return {
    type: STRATEGYSTYLE_FILTER_SELECTED,
    payload: {
      ...strategyStyle
    }
  }
}

export function removeStrategyStyleFilter (strategyStyleId) {
  return {
    type: STRATEGYSTYLE_FILTER_REMOVED,
    payload: {
      strategyStyleId: strategyStyleId
    }
  }
}

export function loadStrategyTypesSuccess (data) {
  return {
    type: LOAD_STRATEGYTYPES_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadStrategyTypesFailure (error) {
  return {
    type: LOAD_STRATEGYTYPES_FAILURE,
    payload: {
      error: error
    }
  }
}

export function selectStrategyTypeFilter (strategyType) {
  return {
    type: STRATEGYTYPE_FILTER_SELECTED,
    payload: {
      ...strategyType
    }
  }
}

export function removeStrategyTypeFilter (strategyTypeId) {
  return {
    type: STRATEGYTYPE_FILTER_REMOVED,
    payload: {
      strategyTypeId: strategyTypeId
    }
  }
}

export function loadAssetClassesSuccess (data) {
  return {
    type: LOAD_ASSETCLASSES_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function loadAssetClassesFailure (error) {
  return {
    type: LOAD_ASSETCLASSES_FAILURE,
    payload: {
      error: error
    }
  }
}

export function selectAssetClassFilter (assetClass) {
  return {
    type: ASSETCLASS_FILTER_SELECTED,
    payload: {
      ...assetClass
    }
  }
}

export function removeAssetClassFilter (assetClassId) {
  return {
    type: ASSETCLASS_FILTER_REMOVED,
    payload: {
      assetClassId: assetClassId
    }
  }
}
