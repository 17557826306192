import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadAssetClasses } from '../../../../actions/actionCreators'
import ReactHTMLDatalist from 'react-html-datalist'
import { selectAssetClassFilter } from '../../../../actions/actions'

export default function AssetClassesSelect () {
  const [name, setName] = useState('')

  const dispatch = useDispatch()
  const assetClasses = useSelector(({ assetClasses }) => assetClasses.assetClasses, shallowEqual)

  const newOption = assetClass => {
    return {
      text: assetClass.name, value: assetClass.id
    }
  }
  const newAssetClass = option => {
    return {
      name: option.text, id: option.value
    }
  }

  useEffect(() => {
    dispatch(loadAssetClasses(name))
  }, [dispatch, name])

  const handleChange = e => {
    if (e.target.value === '') {
      setName(e.target.text)
      return
    }
    dispatch(selectAssetClassFilter(newAssetClass(e.target)))
  }
  return (
    <>
      <label htmlFor='assetClass-input' className='col-md-1 col-form-label'>Asset Class</label>
      <div className='col-md-3'>
        <ReactHTMLDatalist
          name='assetClass_id'
          onChange={handleChange}
          classNames='form-control'
          options={assetClasses.map(newOption)}
        />
      </div>
    </>
  )
}
