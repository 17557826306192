import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { removeThemeFilter } from '../../../../actions/actions'

export default function ThemesFilters () {
  const themeFilters = useSelector(({ certFilters }) => certFilters.themes, shallowEqual)
  const dispatch = useDispatch()

  const onRemoveThemeClick = (themeId) => {
    dispatch(removeThemeFilter(themeId))
  }
  return (
    <div className='col-md-2'>
      {themeFilters.map((item, i) => {
        return (
          <span key={i} className='badge badge-pill badge-light' style={{ cursor: 'pointer' }} onClick={(e) => onRemoveThemeClick(item.id)}>{item.name}
            <i key={i} className='mdi mdi-cancel' />
          </span>
        )
      })}
    </div>
  )
}
