import {
  loadCertsFailure,
  certsLoading,
  loadCertInfoSuccess,
  loadCertInfoFailure,
  certInfoLoading,
  loadCertContactSuccess,
  loadCertContactFailure,
  certContactLoading,
  loadCertPerfSuccess,
  loadCertPerfFailure,
  certPerfLoading,
  loadCertsPerfSuccess,
  loadCertsPerfFailure,
  certsPerfLoading,
  loadBenchPerfSuccess,
  loadBenchPerfFailure,
  benchPerfLoading,
  loadCertsSuccess,
  loadTagsFailure,
  loadTagsSuccess,
  loadThemesFailure,
  loadThemesSuccess,
  loadInvestmentTypesSuccess,
  loadInvestmentTypesFailure,
  loadCompanyTypesSuccess,
  loadCompanyTypesFailure,
  loadStrategyStylesSuccess,
  loadStrategyStylesFailure,
  loadStrategyTypesSuccess,
  loadStrategyTypesFailure,
  loadAssetClassesSuccess,
  loadAssetClassesFailure,
  loadProvidersFailure,
  loadProvidersSuccess,
  providersLoading,
  loadIssuersFailure,
  loadIssuersSuccess,
  issuersLoading,
  loadProviderInfoSuccess,
  loadProviderInfoFailure,
  providerInfoLoading,
  loadProviderContactSuccess,
  loadProviderContactFailure,
  providerContactLoading,
  loadIssuerInfoSuccess,
  loadIssuerInfoFailure,
  issuerInfoLoading,
  loadUserFailure,
  loadUserSuccess,
  userLoading,
  editUserFailure,
  editUserSuccess,
  userEditing
} from './actions'
import {
  getCertificates,
  getIssuersByName,
  getProvidersByName,
  getCertificateInfo,
  postCertificateContact,
  getCertificatePerf,
  getCertificatesPerf,
  getBenchmarkPerf,
  getProviderInfo,
  postProviderContact,
  getIssuerInfo,
  getTagsByName,
  getThemesByName,
  getInvestmentTypesByName,
  getCompanyTypesByName,
  getStrategyTypesByName,
  getStrategyStylesByName,
  getAssetClassesByName,
  getUserProfile,
  editUserProfile
} from '../api'

export const editUser = (data) => async (dispatch) => {
  dispatch(userEditing())
  const result = await editUserProfile(data)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(editUserFailure('something went wrong'))
    return
  }
  dispatch(editUserSuccess(result.data))
}

export const loadUser = () => async (dispatch) => {
  dispatch(userLoading())
  const result = await getUserProfile()
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadUserFailure('something went wrong'))
    return
  }
  dispatch(loadUserSuccess(result.data))
}

export const loadCerts = (filters, sortBy, pageLimit) => async (dispatch) => {
  dispatch(certsLoading())
  const result = await getCertificates(filters.ids, filters.providers, filters.tags, filters.themes, filters.investmentTypes, filters.strategyStyles, filters.strategyTypes, filters.assetClasses, filters.issuers, sortBy, pageLimit)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadCertsFailure('something went wrong'))
    return
  }
  dispatch(loadCertsSuccess(result.data))
}

export const loadProviders = (name, sortBy) => async (dispatch) => {
  dispatch(providersLoading())
  const result = await getProvidersByName(name, sortBy)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadProvidersFailure('something went wrong'))
    return
  }
  dispatch(loadProvidersSuccess(result.data))
}

export const loadIssuers = (name, sortBy) => async (dispatch) => {
  dispatch(issuersLoading())
  const result = await getIssuersByName(name, sortBy)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadIssuersFailure('something went wrong'))
    return
  }
  dispatch(loadIssuersSuccess(result.data))
}

export const loadTags = (name) => async (dispatch) => {
  const result = await getTagsByName(name)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadTagsFailure('something went wrong'))
    return
  }
  dispatch(loadTagsSuccess(result.data))
}

export const loadThemes = (name) => async (dispatch) => {
  const result = await getThemesByName(name)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadThemesFailure('something went wrong'))
    return
  }
  dispatch(loadThemesSuccess(result.data))
}

export const loadInvestmentTypes = (name) => async (dispatch) => {
  const result = await getInvestmentTypesByName(name)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadInvestmentTypesFailure('something went wrong'))
    return
  }
  dispatch(loadInvestmentTypesSuccess(result.data))
}

export const loadCompanyTypes = (name) => async (dispatch) => {
  const result = await getCompanyTypesByName(name)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadCompanyTypesFailure('something went wrong'))
    return
  }
  dispatch(loadCompanyTypesSuccess(result.data))
}

export const loadStrategyStyles = (name) => async (dispatch) => {
  const result = await getStrategyStylesByName(name)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadStrategyStylesFailure('something went wrong'))
    return
  }
  dispatch(loadStrategyStylesSuccess(result.data))
}

export const loadStrategyTypes = (name) => async (dispatch) => {
  const result = await getStrategyTypesByName(name)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadStrategyTypesFailure('something went wrong'))
    return
  }
  dispatch(loadStrategyTypesSuccess(result.data))
}

export const loadAssetClasses = (name) => async (dispatch) => {
  const result = await getAssetClassesByName(name)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadAssetClassesFailure('something went wrong'))
    return
  }
  dispatch(loadAssetClassesSuccess(result.data))
}

export const loadCertInfo = (certificateId) => async (dispatch) => {
  dispatch(certInfoLoading())
  const result = await getCertificateInfo(certificateId)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadCertInfoFailure('something went wrong'))
    return
  }
  dispatch(loadCertInfoSuccess(result.data))
}

export const requestCertContact = (certificateId, data) => async (dispatch) => {
  dispatch(certContactLoading())
  const result = await postCertificateContact(certificateId, data)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadCertContactFailure('something went wrong'))
    return
  }
  dispatch(loadCertContactSuccess(result.data))
}

export const loadCertPerf = (certificateId) => async (dispatch) => {
  dispatch(certPerfLoading())
  const result = await getCertificatePerf(certificateId)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadCertPerfFailure('something went wrong'))
    return
  }
  dispatch(loadCertPerfSuccess(result.data))
}

export const loadCertsPerf = (certificateIds) => async (dispatch) => {
  dispatch(certsPerfLoading())
  const result = await getCertificatesPerf(certificateIds)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadCertsPerfFailure('something went wrong'))
    return
  }
  dispatch(loadCertsPerfSuccess(result.data))
}

export const loadBenchPerf = (benchmarkId) => async (dispatch) => {
  dispatch(benchPerfLoading())
  const result = await getBenchmarkPerf(benchmarkId)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadBenchPerfFailure('something went wrong'))
    return
  }
  dispatch(loadBenchPerfSuccess(result.data))
}

export const loadProviderInfo = (providerId) => async (dispatch) => {
  dispatch(providerInfoLoading())
  const result = await getProviderInfo(providerId)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadProviderInfoFailure('something went wrong'))
    return
  }
  dispatch(loadProviderInfoSuccess(result.data))
}

export const requestProviderContact = (providerId, data) => async (dispatch) => {
  dispatch(providerContactLoading())
  const result = await postProviderContact(providerId, data)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadProviderContactFailure('something went wrong'))
    return
  }
  dispatch(loadProviderContactSuccess(result.data))
}

export const loadIssuerInfo = (issuerId) => async (dispatch) => {
  dispatch(issuerInfoLoading())
  const result = await getIssuerInfo(issuerId)
  if (result.status === 403) {
    window.location.href = '/forbidden'
    return
  }
  if (result.status === 500) {
    dispatch(loadIssuerInfoFailure('something went wrong'))
    return
  }
  dispatch(loadIssuerInfoSuccess(result.data))
}
