import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Auth0Provider } from '@auth0/auth0-react'
import { CookiesProvider } from 'react-cookie'

import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers/reducers'
import { Provider } from 'react-redux'
import config from './config'

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <Auth0Provider
        domain={config.oauth0.DOMAIN}
        clientId={config.oauth0.CLIENTID}
        redirectUri={window.location.origin}
        cacheLocation='localstorage'
        useRefreshTokens
      >
        <App />
      </Auth0Provider>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
