import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { removeStrategyTypeFilter } from '../../../../actions/actions'

export default function StrategyTypesFilters () {
  const strategyTypeFilters = useSelector(({ certFilters }) => certFilters.strategyTypes, shallowEqual)
  const dispatch = useDispatch()

  const onRemoveStrategyTypeClick = (strategyTypeId) => {
    dispatch(removeStrategyTypeFilter(strategyTypeId))
  }
  return (
    <div className='col-md-2'>
      {strategyTypeFilters.map((item, i) => {
        return (
          <span key={i} className='badge badge-pill badge-light' style={{ cursor: 'pointer' }} onClick={(e) => onRemoveStrategyTypeClick(item.id)}>{item.name}
            <i key={i} className='mdi mdi-cancel' />
          </span>
        )
      })}
    </div>
  )
}
