import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CertificateTimeline from '../CertificateTimeline'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadCertInfo, requestCertContact } from '../../actions/actionCreators'
import Loading from '../loading'
import { format100Percentage } from '../../Formats/Number'
import Page from '../../Page'
import ModalContact from '../modal'
import Alert from '../alert'

const CertificateDetail = () => {
  const dispatch = useDispatch()
  const { certificateId } = useParams()
  const certInfo = useSelector(({ certInfo }) => certInfo.certInfo, shallowEqual)
  const isLoading = useSelector(({ certInfo }) => certInfo.isLoading, shallowEqual)

  const [showModalContact, setShowModalContact] = useState(false)
  const [showModalContactSuccess, setShowModalContactSuccess] = useState(false)
  const [getModalContent, setModalContact] = useState('')
  const handleModalContactClose = () => setShowModalContact(false)
  const handleModalContactShow = () => setShowModalContact(true)

  useEffect(() => {
    dispatch(loadCertInfo(certificateId))
  }, [dispatch, certificateId])

  const onContact = () => {
    setShowModalContact(false)
    setShowModalContactSuccess(true)
    setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowModalContactSuccess(false)
    }, 5000)
    dispatch(requestCertContact(certInfo.id, { body: getModalContent }))
  }

  const openFactsheet = () => {
    window.open(certInfo.link_factsheet)
  }

  const openProspectus = () => {
    window.open(certInfo.link_prospectus)
  }

  const openTerms = () => {
    window.open(certInfo.link_terms)
  }

  function cleanCurrency (string) {
    if (string === undefined || string === '') {
      return string
    }
    return '(' + string + ')'
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <Page title='myAMC.ch - Certificate'>

      <div className='header-bg'>
        <header id='topnav2'>

          <div className='container-fluid'>
            <div className='row align-items-center '>
              <div className='col-md-8'>
                <div className='page-title-box'>
                  <div className='mb-3'>
                    <h3 className='d-inline'>{certInfo.name} </h3><h5 className='d-inline'>{cleanCurrency(certInfo.currency)}</h5>
                  </div>
                  <div className='mb-3'>
                    <h6 className='d-inline'><small>Frequency: </small>{certInfo.subscription_frequency_name} </h6>
                    <h6 className='d-inline ml-4'><small>Last update: </small>{certInfo.last_price_date}</h6>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: certInfo.description }} />
                  <div dangerouslySetInnerHTML={{ __html: certInfo.objective }} />
                  <div dangerouslySetInnerHTML={{ __html: certInfo.policy }} />
                  <div dangerouslySetInnerHTML={{ __html: certInfo.note }} />
                  <p>
                    {certInfo.inception_date !== undefined
                      ? <>Inception Time: {certInfo.inception_date.substring(0, 10)}</>
                      : ''}
                  </p>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='d-md-block w-100 text-right'>
                  <div className='d-md-block w-100' style={{ backgroundColor: certInfo.provider.logo_background }}>
                    {certInfo.provider.logo !== undefined
                      ? <img src={certInfo.provider.logo} alt={certInfo.provider.name + ' logo'} width='200' />
                      : ''}
                  </div>
                  <h5 className='w-100 font-weight-normal'>By <b><Link to={'/allocator/' + certInfo.provider.id}>{certInfo.provider.name}</Link></b></h5>
                </div>

                <div className='d-md-block w-100'>
                  <h6 className='text-right w-100'>
                    {certInfo.themes !== null && certInfo.themes.map((m, i) =>
                      <span key={i} className='ml-2 badge badge-primary'>{m.name}</span>
                    )}
                  </h6>
                  <h6 className='text-right w-100'>
                    {certInfo.tags !== null && certInfo.tags.map((m, i) =>
                      <span key={i} className='ml-2 badge badge-secondary'>{m.name}</span>
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>

                  {(certInfo.denomination !== undefined || certInfo.original_isin !== undefined || certInfo.original_valoren !== undefined) &&
                    <div className='d-md-inline w-100 mb-2'>
                      <button
                        className='btn btn-primary waves-effect waves-light' type='button' data-toggle='collapse'
                        data-target='#collapseEmail' aria-expanded='false' aria-controls='collapseEmail'
                      >
                        Show Strategy Identifier
                      </button>
                    </div>}

                  {((certInfo.provider.contact_email !== undefined || certInfo.provider.email !== undefined) &&
                    <div className='d-md-inline w-100 mb-4 ml-3'>
                      <button
                        onClick={handleModalContactShow}
                        className='btn btn-primary waves-effect waves-light' type='button'
                      >
                        Get an introduction
                      </button>
                    </div>
                    )}

                  {certInfo.link_factsheet !== undefined && certInfo.link_factsheet !== '' &&
                    <div className='d-md-inline w-100 mb-4 ml-3'>
                      <button
                        onClick={openFactsheet}
                        className='btn btn-secondary waves-effect waves-light' type='button'
                      >
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-file-pdf' viewBox='0 0 16 16'>
                          <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                          <path d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z' />
                        </svg>
                        Factsheet
                      </button>
                    </div>}

                  {certInfo.link_prospectus !== undefined && certInfo.link_prospectus !== '' &&
                    <div className='d-md-inline w-100 mb-4 ml-3'>
                      <button
                        onClick={openProspectus}
                        className='btn btn-secondary waves-effect waves-light' type='button'
                      >
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-file-pdf' viewBox='0 0 16 16'>
                          <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                          <path d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z' />
                        </svg>
                        Prospectus
                      </button>
                    </div>}

                  {certInfo.link_terms !== undefined && certInfo.link_terms !== '' &&
                    <div className='d-md-inline w-100 mb-4 ml-3'>
                      <button
                        onClick={openTerms}
                        className='btn btn-secondary waves-effect waves-light' type='button'
                      >
                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-file-pdf' viewBox='0 0 16 16'>
                          <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                          <path d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z' />
                        </svg>
                        Term Sheet
                      </button>
                    </div>}

                  <ModalContact
                    show={showModalContact}
                    handleAction={onContact}
                    handleClose={handleModalContactClose}
                    title={'Get more information on ' + certInfo.name}
                    contentChange={setModalContact}
                  >
                    <p>You can add a note to personalize your request for an introduction:</p>
                  </ModalContact>

                  <div className='d-block w-100 mt-2 h-25'>
                    <Alert
                      show={showModalContactSuccess}
                      severity='alert'
                    >
                      Message successfully sent
                    </Alert>
                  </div>

                  {(certInfo.denomination !== undefined || certInfo.original_isin !== undefined || certInfo.original_valoren !== undefined) &&
                    <span className='collapse' id='collapseEmail'>
                      <div className='row align-items-center '>

                        {certInfo.denomination !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Denomination</h6>
                              <h6 className='font-weight-bold'>{certInfo.denomination}</h6>
                            </div>
                            </div>
                          : ''}
                        {certInfo.original_isin !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>ISIN</h6>
                              <h6 className='font-weight-bold'>{certInfo.original_isin}</h6>
                            </div>
                            </div>
                          : ''}
                        {certInfo.original_valoren !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>VALOR</h6>
                              <h6 className='font-weight-bold'>{certInfo.original_valoren}</h6>
                            </div>
                            </div>
                          : ''}
                        {certInfo.region !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Region</h6>
                              <h6 className='font-weight-bold'>{certInfo.region}</h6>
                            </div>
                            </div>
                          : ''}
                      </div>
                    </span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row align-items-center'>
                    {certInfo.investment_type_name !== undefined
                      ? <div className='col-md-3'>
                        <div className='page-title-box pt-0'>
                          <h6 className='font-12 font-italic'>Investment Type</h6>
                          <h6 className='font-weight-bold'>{certInfo.investment_type_name}</h6>
                        </div>
                      </div>
                      : ''}
                    {certInfo.strategy_style_name !== undefined
                      ? <div className='col-md-3'>
                        <div className='page-title-box pt-0'>
                          <h6 className='font-12 font-italic'>Strategy Style</h6>
                          <h6 className='font-weight-bold'>{certInfo.strategy_style_name}</h6>
                        </div>
                      </div>
                      : ''}
                    {certInfo.strategy_type_name !== undefined
                      ? <div className='col-md-3'>
                        <div className='page-title-box pt-0'>
                          <h6 className='font-12 font-italic'>Strategy Type</h6>
                          <h6 className='font-weight-bold'>{certInfo.strategy_type_name}</h6>
                        </div>
                      </div>
                      : ''}
                    {certInfo.asset_class_name !== undefined
                      ? <div className='col-md-3'>
                        <div className='page-title-box pt-0'>
                          <h6 className='font-12 font-italic'>Asset Class</h6>
                          <h6 className='font-weight-bold'>{certInfo.asset_class_name}</h6>
                        </div>
                      </div>
                      : ''}
                  </div>

                  {certInfo.issuance_fee !== undefined || certInfo.distribution_fee !== undefined ||
              certInfo.management_fee !== undefined || certInfo.performance_fee !== undefined ||
              certInfo.platform_fee !== undefined || certInfo.admin_fee !== undefined ||
              certInfo.custody_fee !== undefined || certInfo.storage_fee !== undefined ||
              certInfo.general_expense !== undefined || certInfo.misc_fee !== undefined ||
              certInfo.misc_fee_detail !== undefined || certInfo.sec_market_spread !== undefined
                    ? <span>
                      <h6 className='font-14 font-italic font-weight-bold'>Fees</h6>
                      <div className='row align-items-center'>
                        {certInfo.issuance_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Issuance Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.issuance_fee)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.distribution_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Distribution Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.distribution_fee)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.management_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Management Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.management_fee)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.performance_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Performance Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.performance_fee) + (certInfo.performance_fee_hwm ? ' including HWM' : '')}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.platform_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Platform Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.platform_fee)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.admin_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Admin Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.admin_fee)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.custody_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Custody Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.custody_fee)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.storage_fee !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Storage Fees</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.storage_fee)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.general_expense !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>General Expense</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.general_expense)}</h6>
                            </div>
                          </div>
                          : ''}
                        {certInfo.misc_fee !== undefined || certInfo.misc_fee_detail !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Misc Fees</h6>
                              {certInfo.misc_fee !== undefined
                                ? <h6 className='font-weight-bold'>{format100Percentage(certInfo.misc_fee)}</h6>
                                : ''}
                              <p className=''>{certInfo.misc_fee_detail}</p>
                            </div>
                          </div>
                          : ''}
                        {certInfo.sec_market_spread !== undefined
                          ? <div className='col-md-3'>
                            <div className='page-title-box pt-0'>
                              <h6 className='font-12 font-italic'>Secondary Market Spread</h6>
                              <h6 className='font-weight-bold'>{format100Percentage(certInfo.sec_market_spread)}</h6>
                            </div>
                          </div>
                          : ''}
                      </div>
                      </span>
                    : ''}

                  <div className='d-md-block w-100 mb-2'>
                    <button
                      className='btn btn-primary waves-effect waves-light' type='button' data-toggle='collapse'
                      data-target='#collapseKeyPartiesLiquidity' aria-expanded='false' aria-controls='collapseKeyPartiesLiquidity'
                    >
                      Show Key Parties & Liquidity Information
                    </button>
                  </div>

                  <span className='collapse' id='collapseKeyPartiesLiquidity'>
                    {certInfo.issuer !== undefined || certInfo.calculating_agent !== undefined ||
                  certInfo.broker !== undefined || certInfo.paying_agent !== undefined || certInfo.crypto_custodian !== undefined
                      ? <span>
                        <h6 className='font-14 font-italic font-weight-bold'>Key Parties</h6>
                        <div className='row align-items-center'>
                          {certInfo.issuer !== undefined
                            ? [certInfo.issuer_id !== undefined
                                ? (<div className='col-md-3'>
                                  <div className='page-title-box pt-0'>
                                    <h6 className='font-12 font-italic'>Issuer</h6>
                                    <Link style={{ textDecoration: 'underline', textDecorationColor: '#e51b23', textDecorationThickness: '2px' }} to={'/issuer/' + certInfo.issuer_id}><h6 className='font-weight-bold'>{certInfo.issuer}</h6></Link>
                                  </div>
                                   </div>)
                                : (<div className='col-md-3'>
                                  <div className='page-title-box pt-0'>
                                    <h6 className='font-12 font-italic'>Issuer</h6>
                                    <h6 className='font-weight-bold'>{certInfo.issuer}</h6>
                                  </div>
                                   </div>)]
                            : ''}
                          {certInfo.issuer_platform !== undefined
                            ? [certInfo.issuer_platform_id !== undefined
                                ? (<div className='col-md-3'>
                                  <div className='page-title-box pt-0'>
                                    <h6 className='font-12 font-italic'>Issuer</h6>
                                    <Link style={{ textDecoration: 'underline', textDecorationColor: '#e51b23', textDecorationThickness: '2px' }} to={'/issuer/' + certInfo.issuer_platform_id}><h6 className='font-weight-bold'>{certInfo.issuer_platform}</h6></Link>
                                  </div>
                                   </div>)
                                : (<div className='col-md-3'>
                                  <div className='page-title-box pt-0'>
                                    <h6 className='font-12 font-italic'>Issuer Platform</h6>
                                    <h6 className='font-weight-bold'>{certInfo.issuer_platform}</h6>
                                  </div>
                                   </div>)]
                            : ''}
                          {certInfo.calculating_agent !== undefined
                            ? <div className='col-md-3'>
                              <div className='page-title-box pt-0'>
                                <h6 className='font-12 font-italic'>Calculating Agent</h6>
                                <h6 className='font-weight-bold'>{certInfo.calculating_agent}</h6>
                              </div>
                              </div>
                            : ''}
                          {certInfo.broker !== undefined
                            ? <div className='col-md-3'>
                              <div className='page-title-box pt-0'>
                                <h6 className='font-12 font-italic'>Broker</h6>
                                <h6 className='font-weight-bold'>{certInfo.broker}</h6>
                              </div>
                              </div>
                            : ''}
                          {certInfo.paying_agent !== undefined
                            ? <div className='col-md-3'>
                              <div className='page-title-box pt-0'>
                                <h6 className='font-12 font-italic'>Paying Agent</h6>
                                <h6 className='font-weight-bold'>{certInfo.paying_agent}</h6>
                              </div>
                              </div>
                            : ''}
                          {certInfo.crypto_custodian !== undefined
                            ? <div className='col-md-3'>
                              <div className='page-title-box pt-0'>
                                <h6 className='font-12 font-italic'>Crypto Custodian</h6>
                                <h6 className='font-weight-bold'>{certInfo.crypto_custodian}</h6>
                              </div>
                              </div>
                            : ''}
                        </div>
                      </span>
                      : ''}

                    <h6 className='font-14 font-italic font-weight-bold'>Liquidity</h6>
                    <div className='row align-items-center'>
                      <div className='col-md-3'>
                        <div className='page-title-box pt-0'>
                          <h6 className='font-12 font-italic'>Subscription Frequency</h6>
                          <h6 className='font-weight-bold'>{certInfo.subscription_frequency_name}</h6>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='page-title-box pt-0'>
                          <h6 className='font-12 font-italic'>Redemption Frequency</h6>
                          <h6 className='font-weight-bold'>{certInfo.redemption_frequency_name}</h6>
                        </div>
                      </div>
                      <div className='col-md-3'>
                        <div className='page-title-box pt-0'>
                          <h6 className='font-12 font-italic'>Redemption Frequency</h6>
                          <h6 className='font-weight-bold'>{certInfo.redemption_notice_name}</h6>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <CertificateTimeline />
            </div>
          </div>
        </div>
      </div>

    </Page>
  )
}

export default CertificateDetail
