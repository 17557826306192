import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadStrategyTypes } from '../../../../actions/actionCreators'
import ReactHTMLDatalist from 'react-html-datalist'
import { selectStrategyTypeFilter } from '../../../../actions/actions'

export default function StrategyTypesSelect () {
  const [name, setName] = useState('')

  const dispatch = useDispatch()
  const strategyTypes = useSelector(({ strategyTypes }) => strategyTypes.strategyTypes, shallowEqual)

  const newOption = strategyType => {
    return {
      text: strategyType.name, value: strategyType.id
    }
  }
  const newStrategyType = option => {
    return {
      name: option.text, id: option.value
    }
  }

  useEffect(() => {
    dispatch(loadStrategyTypes(name))
  }, [dispatch, name])

  const handleChange = e => {
    if (e.target.value === '') {
      setName(e.target.text)
      return
    }
    dispatch(selectStrategyTypeFilter(newStrategyType(e.target)))
  }
  return (
    <>
      <label htmlFor='strategyType-input' className='col-md-1 col-form-label'>Strat. Type</label>
      <div className='col-md-3'>
        <ReactHTMLDatalist
          name='strategyType_id'
          onChange={handleChange}
          classNames='form-control'
          options={strategyTypes.map(newOption)}
        />
      </div>
    </>
  )
}
