import React, { useEffect, useState } from 'react'

import { createInvestor, getInvestor } from '../../api'
import { useHistory } from 'react-router-dom'

const BecomeInvestorBtn = () => {
  const [investor, setInvestor] = useState({ active_subs: [] })
  const history = useHistory()
  useEffect(() => {
    getInvestor().then((resp) => {
      setInvestor(resp.data)
    })
  }, [])

  const handleClick = async () => {
    if (investor.auth_id === undefined) {
      createInvestor().then(resp => { setInvestor(resp.data) })
    }
    history.push('/prices')
  }
  return (
    <>
    </>
  /* <>
      {(investor.active_subs == null || investor.active_subs.length === 0)
        ? <button className='btn btn-primary' onClick={handleClick}>Become an investor</button>
        : <p>Subscription ends at {investor.active_subs[0].period_end}</p>}
    </> */
  )
}

export default BecomeInvestorBtn
