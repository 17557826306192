import { NavLink, Link } from 'react-router-dom'
import Profile from './Profile'
import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { slide as Menu } from 'react-burger-menu'
import { decorator as reduxBurgerMenu } from 'redux-burger-menu'
import { useAuth0 } from '@auth0/auth0-react'

const Navbar = () => {
  const { userInfo, isLoading } = useSelector(({ user }) => user, shallowEqual)

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector('.header-bg')
    const scrollTop = window.scrollY
    scrollTop >= 50 ? header.classList.add('header-is-sticky') : header.classList.remove('header-is-sticky')
  }

  return (
    <div className='header-bg'>
      <header id='topnav'>
        <div className='topbar-main'>
          <div className='container-fluid' style={{ height: '70px' }}>

            <div>
              <span id='navigation-burger'>
                <Menu disableAutoFocus right outerContainerId='root' id='burger-menu'>
                  <NavLink to='/' exact activeClassName='nav-active' className='pr-1 pl-1'>
                    <span className='col-md-12'><i className='dripicons-meter' />Dashboard</span>
                  </NavLink>

                  <NavLink to='/explore' exact activeClassName='nav-active' className='pr-1 pl-1'>
                    <span className='col-md-12'>
                      <i className='dripicons-search' />
                      Explore
                    </span>
                  </NavLink>

                  {(userInfo.id === 'anonymous')
                    ? <NavLink to='/' exact activeClassName='nav-disabled' className='pr-1 pl-1'>
                      <span className='col-md-12'>
                        <i className='dripicons-pin' />
                        Compare
                      </span>
                      </NavLink>
                    : <NavLink to='/compare' exact activeClassName='nav-active' className='pr-1 pl-1'>
                      <span className='col-md-12'>
                        <i className='dripicons-pin' />
                        Compare
                      </span>
                      </NavLink>}

                  {(userInfo.id === 'anonymous')
                    ? <NavLink to='/' exact activeClassName='nav-disabled' className='pr-1 pl-1'>
                      <span className='col-md-12'>
                        <i className='dripicons-user-id' />
                        Allocators
                      </span>
                      </NavLink>
                    : <NavLink to='/allocators' exact activeClassName='nav-active' className='pr-1 pl-1'>
                      <span className='col-md-12'>
                        <i className='dripicons-user-id' />
                        Allocators
                      </span>
                      </NavLink>}

                  {(userInfo.id === 'anonymous')
                    ? <NavLink to='/' exact activeClassName='nav-disabled' className='pr-1 pl-1'>
                      <span className='col-md-12'>
                        <i className='dripicons-trophy' />
                        Issuers
                      </span>
                      </NavLink>
                    : <NavLink to='/issuers' exact activeClassName='nav-active' className='pr-1 pl-1'>
                      <span className='col-md-12'>
                        <i className='dripicons-trophy' />
                        Issuers
                      </span>
                      </NavLink>}

                </Menu>
              </span>
              <Link to='/' className='logo' style={{ display: 'inline-block' }}>
                <img src='/assets/images/logo-dark.png' className='logo-lg' alt='' height='36' />
              </Link>
            </div>

            <div className='menu-extras topbar-custom navbar float-left ml-1 pb-0'>
              <div className='navbar-right mx-4 list-inline float-left mb-0'>
                <div id='navigation'>
                  <ul className='navigation-menu'>
                    <li className='has-submenu'>
                      <NavLink to='/' exact activeClassName='nav-active' className='pr-1 pl-1'>
                        <span className='col-md-12'><i className='dripicons-meter' />Dashboard</span>
                      </NavLink>
                    </li>
                    <li className='has-submenu'>
                      <NavLink to='/explore' exact activeClassName='nav-active' className='pr-1 pl-1'>
                        <span className='col-md-12'>
                          <i className='dripicons-search' />
                          Explore
                        </span>
                      </NavLink>
                    </li>
                    <li className='has-submenu'>
                      {(userInfo.id === 'anonymous')
                        ? <NavLink to='/' exact className='nav-disabled pr-1 pl-1'>
                          <span className='col-md-12'>
                            <i className='dripicons-pin' />
                            Compare
                          </span>
                          </NavLink>
                        : <NavLink to='/compare' exact activeClassName='nav-active' className='pr-1 pl-1'>
                          <span className='col-md-12'>
                            <i className='dripicons-pin' />
                            Compare
                          </span>
                          </NavLink>}
                    </li>
                    <li className='has-submenu'>
                      {(userInfo.id === 'anonymous')
                        ? <NavLink to='/' exact className='nav-disabled pr-1 pl-1'>
                          <span className='col-md-12'>
                            <i className='dripicons-user-id' />
                            Allocators
                          </span>
                        </NavLink>
                        : <NavLink to='/allocators' exact activeClassName='nav-active' className='pr-1 pl-1'>
                          <span className='col-md-12'>
                            <i className='dripicons-user-id' />
                            Allocators
                          </span>
                          </NavLink>}
                    </li>
                    <li className='has-submenu'>
                      {(userInfo.id === 'anonymous')
                        ? <NavLink to='/' exact className='nav-disabled pr-1 pl-1'>
                          <span className='col-md-12'>
                            <i className='dripicons-trophy' />
                            Issuers
                          </span>
                        </NavLink>
                        : <NavLink to='/issuers' exact activeClassName='nav-active' className='pr-1 pl-1'>
                          <span className='col-md-12'>
                            <i className='dripicons-trophy' />
                            Issuers
                          </span>
                          </NavLink>}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className='menu-extras topbar-custom navbar p-0'>
              <div className='navbar-right ml-auto list-inline float-right mb-0'>
                <div>
                  <Profile />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default reduxBurgerMenu(Navbar)
