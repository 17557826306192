import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './Components/navbar'
import PageViews from './Components/pageviews'
import Dashboard from './Components/Dashboard'
import Explore from './Components/Explore'
import PageCompare from './Components/PageCompare'
import PageProviders from './Components/PageProviders'
import PageIssuers from './Components/PageIssuers'
import PageProfile from './Components/PageProfile'
import Forbidden from './Components/Forbidden'
import CertificateDetail from './Components/CertificateDetail'
import ProviderDetail from './Components/ProviderDetail'
import IssuerDetail from './Components/IssuerDetail'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from './Components/loading'
import { Prices } from './Components/Stripe'
import { Subscribe } from './Components/Stripe/subscribe'
import { useCookies } from 'react-cookie'
import QualifiedInvestor from './Components/QualifiedInvestor'
import PageAssetClass from './Components/PageAssetClass'

export default function App () {
  const { getAccessTokenSilently } = useAuth0()
  const [isLoading, setIsLoading] = useState(true)
  const [cookies, setCookie] = useCookies(['qualifiedSwissInvestor'])
  useEffect(() => {
    // we cache token in session store. if
    // TODO: figure out how and when to invalidate it
    if (window.sessionStorage.getItem('token') === null) {
      getAccessTokenSilently().then(t => {
        window.sessionStorage.setItem('token', t)
        setIsLoading(false)
      }).catch(e => {
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [getAccessTokenSilently])
  if (isLoading) {
    return (<Loading />)
  }
  function onSubmit (newName) {
    setCookie('qualifiedSwissInvestor', true, { path: '/' })
  }
  if (!cookies.qualifiedSwissInvestor) {
    return (
      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='card'>
            <div className='card-body'>
              <Router>
                <div id='wrapper'>
                  <Navbar />
                  <div>
                    <QualifiedInvestor name={cookies.qualifiedSwissInvestor} onSubmit={onSubmit} />
                  </div>
                </div>
              </Router>
            </div>
          </div>
        </div>
      </div>

    )
  }
  return (
    <div className='wrapper'>
      <div className='container-fluid'>
        <div className='card'>
          <div className='card-body'>
            <Router>
              <div id='wrapper'>
                <Navbar />
                <PageViews />
                <Switch>
                  <Route exact path='/' component={Dashboard} />
                  <Route exact path='/dashboard' component={Dashboard} />
                  <Route exact path='/explore' component={Explore} />
                  <Route exact path='/profile' component={PageProfile} />
                  <Route exact path='/compare' component={PageCompare} />
                  <Route exact path='/asset-class/:assetClassName' component={PageAssetClass} />
                  <Route path='/certificate/:certificateId' component={CertificateDetail} />
                  <Route path='/allocator/:providerId' component={ProviderDetail} />
                  <Route path='/allocators' component={PageProviders} />
                  <Route path='/issuer/:issuerId' component={IssuerDetail} />
                  <Route path='/issuers' component={PageIssuers} />
                  <Route path='/forbidden' component={Forbidden} />
                  <Route path='/prices' component={Prices} />
                  <Route path='/subscribe/:priceLookupKey' component={Subscribe} />
                </Switch>
              </div>

            </Router>
          </div>
        </div>
      </div>
    </div>
  )
}
