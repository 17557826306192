import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadCerts } from '../../actions/actionCreators'
import Loading from '../loading'
import { Link } from 'react-router-dom'
import { formatPercentage } from '../../Formats/Number'
import { formatBadgeText } from '../../Formats/Badge'
import { formatCaret } from '../../Formats/Caret'

const DashboardAssetClass = () => {
  const dispatch = useDispatch()
  const { certs, isLoading } = useSelector(({ certs }) => certs, shallowEqual)
  const certFilters = {
    ids: [],
    providers: [],
    issuers: [],
    tags: [],
    themes: [],
    investmentTypes: [],
    strategyStyles: [],
    strategyTypes: [],
    assetClasses: []
  }

  const [sortBy, setSortBy] = useState('ytd_perf')
  const setSortBy1dPerf = () => setSortBy('1d_perf')
  const setSortBy1mPerf = () => setSortBy('1m_perf')
  const setSortByYtdPerf = () => setSortBy('ytd_perf')

  useEffect(() => {
    dispatch(loadCerts(certFilters, sortBy, 5000))
  }, [dispatch, sortBy])

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, [])
  }

  const groupedCertificate = groupBy(certs.filter((d, k) => d.strategy_type_id === 1 || d.strategy_type_id === 3), 'asset_class_name')

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <div className='container-fluid'>
        <div>
          {Object.keys(groupedCertificate).map((assetclass, j) => (
            <div className='row' key={'class' + j}>
              {groupedCertificate[assetclass].filter((d, k) => d.strategy_type_id === 1).filter((d, k) => k <= 4).length !== 0 &&
              (<>
                <div className='col-md-12 col-lg-6 mb-3'>

                  <div className='card h-100'>
                    <div className='card-body'>

                      <h6 className='font-14 font-italic font-weight-bold'><Link to={'/asset-class/' + assetclass.toLowerCase()}>{assetclass}</Link> (Long Only): Top YtD Performer</h6>
                      <table
                        className='table table-striped table-bordered dt-responsive nowrap'
                        style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}
                      >
                        <thead>
                          <tr>
                            <th className='col-6'>Name</th>
                            <th
                              className={'col-2 btn-chart' + (sortBy === '1d_perf' ? ' btn-chart-active' : '')}
                              onClick={setSortBy1dPerf}
                            >
                              1d Perf
                            </th>
                            <th
                              className={'col-2 btn-chart' + (sortBy === '1m_perf' ? ' btn-chart-active' : '')}
                              onClick={setSortBy1mPerf}
                            >
                              1m Perf
                            </th>
                            <th
                              className={'col-2 btn-chart' + (sortBy === 'ytd_perf' ? ' btn-chart-active' : '')}
                              onClick={setSortByYtdPerf}
                            >
                              ytd Perf
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedCertificate[assetclass].filter((d, k) => d.strategy_type_id === 1).filter((d, k) => k <= 4).map((item, i) => (
                            <tr key={i}>
                              <td>
                                <div>
                                  <Link to={'certificate/' + item.id}>{item.name}</Link>
                                  <span className='float-right'>
                                    <span className='ml-1 badge badge-primary badge-within' title={item.subscription_frequency_name}>{formatBadgeText(item.subscription_frequency_name)}</span>
                                    <span className='ml-1 badge badge-info badge-within' title={item.strategy_type_name}>{formatBadgeText(item.strategy_type_name)}</span>
                                  </span>
                                </div>
                                <div style={{ fontStyle: 'italic', color: '#888' }}>
                                  @{item.provider.name}
                                </div>
                              </td>
                              <td>{formatPercentage(item.kpi_1day_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_1day_perf)}</span></td>
                              <td>{formatPercentage(item.kpi_1month_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_1month_perf)}</span></td>
                              <td>{formatPercentage(item.kpi_ytd_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_ytd_perf)}</span></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>

                </div>
              </>
              )}

              {groupedCertificate[assetclass].filter((d, k) => d.strategy_type_id === 3).filter((d, k) => k <= 4).length !== 0 &&
              (<>
                <div className='col-md-12 col-lg-6 mb-3' key={'class' + j}>
                  <div className='card h-100'>
                    <div className='card-body'>

                      <h6 className='font-14 font-italic font-weight-bold'><Link to={'/asset-class/' + assetclass.toLowerCase()}>{assetclass}</Link> (Long/Short): Top YtD Performer</h6>
                      <table
                        className='table table-striped table-bordered dt-responsive nowrap'
                        style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}
                      >
                        <thead>
                          <tr>
                            <th className='col-6'>Name</th>
                            <th
                              className={'col-2 btn-chart' + (sortBy === '1d_perf' ? ' btn-chart-active' : '')}
                              onClick={setSortBy1dPerf}
                            >
                              1d Perf
                            </th>
                            <th
                              className={'col-2 btn-chart' + (sortBy === '1m_perf' ? ' btn-chart-active' : '')}
                              onClick={setSortBy1mPerf}
                            >
                              1m Perf
                            </th>
                            <th
                              className={'col-2 btn-chart' + (sortBy === 'ytd_perf' ? ' btn-chart-active' : '')}
                              onClick={setSortByYtdPerf}
                            >
                              ytd Perf
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedCertificate[assetclass].filter((d, k) => d.strategy_type_id === 3).filter((d, k) => k <= 4).map((item, i) => (
                            <tr key={i}>
                              <td>
                                <div>
                                  <Link to={'certificate/' + item.id}>{item.name}</Link>
                                  <span className='float-right'>
                                    <span className='ml-1 badge badge-primary badge-within' title={item.subscription_frequency_name}>{formatBadgeText(item.subscription_frequency_name)}</span>
                                    <span className='ml-1 badge badge-info badge-within' title={item.strategy_type_name}>{formatBadgeText(item.strategy_type_name)}</span>
                                  </span>
                                </div>
                                <div style={{ fontStyle: 'italic', color: '#888' }}>
                                  @{item.provider.name}
                                </div>
                              </td>
                              <td>{formatPercentage(item.kpi_1day_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_1day_perf)}</span></td>
                              <td>{formatPercentage(item.kpi_1month_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_1month_perf)}</span></td>
                              <td>{formatPercentage(item.kpi_ytd_perf)}<span style={{ float: 'right' }}>{formatCaret(item.kpi_ytd_perf)}</span></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default DashboardAssetClass
