import DashboardAssetClass from '../DashboardAssetClass'
import React from 'react'
import Page from '../../Page'

export default function Dashboard () {
  return (
    <Page title='myAMC.ch - Dashboard'>

      <div className='header-bg'>
        <header id='topnav2'>
          <div className='container-fluid'>
            <div className='row align-items-center '>
              <div className='col-md-8'>
                <h6><i className='dripicons-meter' /> Dashboard</h6>
                <p>Welcome to My AMC Dashboard</p>
              </div>
            </div>
          </div>

        </header>
      </div>

      <DashboardAssetClass />

    </Page>

  )
}
