import React from 'react'
import Page from '../../Page'
import CompareDetail from '../CompareDetail'
import CompareTimeline from '../CompareTimeline'
const PageCompare = () => {
  return (
    <Page title='myAMC.ch - Compare'>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <CompareDetail />
            </div>
          </div>
        </div>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <CompareTimeline />
            </div>
          </div>
        </div>
      </div>

    </Page>
  )
}

export default PageCompare
