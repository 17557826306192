import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import BecomeInvestorBtn from '../InvestorButton'
import { NavLink } from 'react-router-dom'

const Profile = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0()
  if (isLoading) {
    return <div className='spinner-border' role='status' />
  }
  if (!isAuthenticated) {
    return <button className='btn btn-primary mt-3' onClick={() => loginWithRedirect()}>Log In</button>
  }
  return (
    <>
      <li className='dropdown notification-list list-inline-item'>
        <div className='dropdown notification-list nav-pro-img'>
          <a
            className='dropdown-toggle nav-link arrow-none waves-effect nav-user' data-toggle='dropdown'
            href='/' role='button' aria-haspopup='false' aria-expanded='false'
          >
            <img src={user.picture} alt={user.name} className='rounded-circle' />
          </a>
          <div className='dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown'>
            <NavLink className='dropdown-item' to='/profile'><i className='mdi mdi-account-circle' /> {user.name}</NavLink>
            <div className='dropdown-divider' />
            <a onClick={() => logout()} className='dropdown-item text-danger'>
              <i className='mdi mdi-power text-danger' /> Logout
            </a>
          </div>
        </div>
      </li>
      <BecomeInvestorBtn />
    </>
  )
}

export default Profile
