import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { removeAssetClassFilter } from '../../../../actions/actions'

export default function AssetClassesFilters () {
  const assetClassFilters = useSelector(({ certFilters }) => certFilters.assetClasses, shallowEqual)
  const dispatch = useDispatch()

  const onRemoveAssetClassClick = (assetClassId) => {
    dispatch(removeAssetClassFilter(assetClassId))
  }
  return (
    <div className='col-md-2'>
      {assetClassFilters.map((item, i) => {
        return (
          <span key={i} className='badge badge-pill badge-light' style={{ cursor: 'pointer' }} onClick={(e) => onRemoveAssetClassClick(item.id)}>{item.name}
            <i key={i} className='mdi mdi-cancel' />
          </span>
        )
      })}
    </div>
  )
}
