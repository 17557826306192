import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadIssuers } from '../../actions/actionCreators'
import Loading from '../loading'
import { Link } from 'react-router-dom'

const IssuerList = () => {
  const dispatch = useDispatch()
  const issuers = useSelector(({ issuers }) => issuers.issuers, shallowEqual)
  const isLoading = useSelector(({ issuers }) => issuers.isLoading, shallowEqual)

  const [sortBy, setSortBy] = useState('random')
  const setSortByName = () => setSortBy('name')
  const setSortByRandom = () => setSortBy('random')

  useEffect(() => {
    dispatch(loadIssuers('', sortBy))
  }, [dispatch, sortBy])

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      <span>
        <span style={{ marginRight: '8px', marginLeft: '8px' }}>Sort By:</span>
        <span onClick={setSortByName} className={'btn-chart' + (sortBy === 'name' ? ' btn-chart-active' : '')}>Name</span>
        <span onClick={setSortByRandom} className={'btn-chart' + (sortBy === 'random' ? ' btn-chart-active' : '')}>Random</span>
      </span>
      {issuers.map((item, i) => (
        <div key={i} className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <Link to={'issuer/' + item.id}>
                  <div className='row align-items-center'>
                    <div className='col-md-1' style={{ backgroundColor: item.logo_background }}>
                      {item.logo !== undefined
                        ? <img src={item.logo} alt={item.name + ' logo'} width='100%' />
                        : ''}
                    </div>
                    <div className='col-md-2'>
                      <h6>{item.name}</h6>
                    </div>
                    <div className='col-md-3'>
                      {item.motto}
                    </div>
                    <div className='col-md-6'>
                      {item.description}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default IssuerList
