import React, { useState } from 'react'

export default function QualifiedInvestor (props) {
  const [value, setValue] = useState(props.name)

  function onSubmitNew (e) {
    props.onSubmit(value)
    e.preventDefault()
  }
  return (
    <div>
      <div className='header-bg'>
        <header id='topnav2'>
          <div className='container-fluid'>
            <div className='row align-items-center '>
              <div className='col-md-8'>
                <div className='page-title-box'>
                  <h5 className>myAMC.ch - Important legal information</h5>
                </div>
              </div>

            </div>
          </div>
        </header>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-md-block w-100 mb-4'>
                    <h5 className='w-100 font-weight-normal'>Terms and Conditions</h5>
                    <p>Please review our <a target='_blank' href='https://myamc.ch/general-terms-and-conditions/' rel='noreferrer'>terms and conditions</a></p>
                  </div>

                  <div className='d-md-block w-100 mb-4'>
                    <h5 className='w-100 font-weight-normal'>Detailed explanation of investor types</h5>
                    <p>Please review our <a target='_blank' href='https://myamc.ch/investors-definition/' rel='noreferrer'>investor types' definitions</a></p>
                  </div>

                  <h5 className='mb-4 font-weight-normal'>I hereby confirm :
                    <ul className='plus-list'>
                      <li>I accept the above mentioned Terms and Conditions</li>
                      <li>I am a Qualified Investor</li>
                      <li>I am domiciled in Switzerland or of Swiss citizenship</li>
                    </ul>
                  </h5>
                  <form onSubmit={onSubmitNew}>
                    <input className='btn btn-primary btn-lg' type='submit' value='Confirm' />
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

/*
<form onSubmit={onSubmitNew} >
                    <label className='disabled'>
                        Name:
                        <input
                            type="text"
                            value={value}
                            onChange={event => setValue(event.target.value)}

                        />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
 */
