import React from 'react'
import ProviderList from '../ProviderList'
import Page from '../../Page'

export default function PageProviders () {
  return (
    <Page title='myAMC.ch - Allocators'>
      <div className='header-bg'>
        <header id='topnav2'>
          <div className='container-fluid'>
            <div className='row align-items-center '>
              <div className='col-md-8'>
                <h6><i className='dripicons-user-id  mr-2' />Allocators</h6>
                <p>Browse through all our allocators</p>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className='wrapper'>
        <div className='container-fluid'>
          <ProviderList />
        </div>
      </div>
    </Page>
  )
}
