import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadCerts } from '../../actions/actionCreators'
import Loading from '../loading'
import { Link, useParams } from 'react-router-dom'
import { formatNumber, formatPercentage } from '../../Formats/Number'

const IssuerCertificateList = () => {
  const dispatch = useDispatch()
  const { issuerId } = useParams()
  const { certs, isLoading } = useSelector(({ certs }) => certs, shallowEqual)
  const certFilters = {
    ids: [],
    providers: [],
    issuers: [{ id: issuerId }],
    tags: [],
    themes: [],
    investmentTypes: [],
    strategyStyles: [],
    strategyTypes: [],
    assetClasses: []
  }

  const [sortBy, setSortBy] = useState('1y_perf')
  const setSortBy1dPerf = () => setSortBy('1d_perf')
  const setSortBy1mPerf = () => setSortBy('1m_perf')
  const setSortBy3mPerf = () => setSortBy('3m_perf')
  const setSortByYtdPerf = () => setSortBy('ytd_perf')
  const setSortBy1yPerf = () => setSortBy('1y_perf')
  const setSortBy3yPerf = () => setSortBy('3y_perf')
  const setSortBy1ySharpe = () => setSortBy('1y_sharpe')

  useEffect(() => {
    dispatch(loadCerts(certFilters, sortBy, 5000))
  }, [issuerId, dispatch, sortBy])

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, [])
  }

  const groupedCertificate = groupBy(certs, 'asset_class_name')

  if (isLoading) {
    return <Loading />
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <h4 className='mt-0 header-title mb-4'>Strategies we manage</h4>

        <table
          className='table table-striped table-bordered dt-responsive nowrap'
          style={{ borderCollapse: 'collapse', borderSpacing: '0', width: '100%' }}
        >
          <thead>
            <tr>
              <th />
              <th>Last Nav</th>
              <th
                className={'btn-chart' + (sortBy === '1d_perf' ? ' btn-chart-active' : '')}
                onClick={setSortBy1dPerf}
              >
                1d Perf
              </th>
              <th
                className={'btn-chart' + (sortBy === '1m_perf' ? ' btn-chart-active' : '')}
                onClick={setSortBy1mPerf}
              >
                1m Perf
              </th>
              <th
                className={'btn-chart' + (sortBy === '3m_perf' ? ' btn-chart-active' : '')}
                onClick={setSortBy3mPerf}
              >
                3m Perf
              </th>
              <th
                className={'btn-chart' + (sortBy === 'ytd_perf' ? ' btn-chart-active' : '')}
                onClick={setSortByYtdPerf}
              >
                ytd Perf
              </th>
              <th
                className={'btn-chart' + (sortBy === '1y_perf' ? ' btn-chart-active' : '')}
                onClick={setSortBy1yPerf}
              >
                1y Perf
              </th>
              <th
                className={'btn-chart' + (sortBy === '3y_perf' ? ' btn-chart-active' : '')}
                onClick={setSortBy3yPerf}
              >
                3y Perf
              </th>
              <th
                className={'btn-chart' + (sortBy === '1y_sharpe' ? ' btn-chart-active' : '')}
                onClick={setSortBy1ySharpe}
              >
                1y Sharpe
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedCertificate).map((assetclass, j) => (
              <>
                <tr key={'class' + j}>
                  <td>
                    <h5>{assetclass}</h5>
                  </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                </tr>
                {groupedCertificate[assetclass].map((item, i) => (
                  <tr key={i}>
                    <td>
                      <Link to={'/certificate/' + item.id}>{item.name}</Link>
                    </td>
                    <td>{formatNumber(item.last_price)}</td>
                    <td>{formatPercentage(item.kpi_1day_perf)}</td>
                    <td>{formatPercentage(item.kpi_1month_perf)}</td>
                    <td>{formatPercentage(item.kpi_3months_perf)}</td>
                    <td>{formatPercentage(item.kpi_ytd_perf)}</td>
                    <td>{formatPercentage(item.kpi_1year_perf)}</td>
                    <td>{formatPercentage(item.kpi_3years_perf)}</td>
                    <td>{formatNumber(item.kpi_1year_sharpe_perf)}</td>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default IssuerCertificateList
