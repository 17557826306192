import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { loadIssuerInfo } from '../../actions/actionCreators'
import Loading from '../loading'
import Page from '../../Page'
import { cleanURL } from '../../Formats/Url'
import IssuerCertificateList from '../IssuerCertificateList'

const IssuerDetail = () => {
  const dispatch = useDispatch()
  const { issuerId } = useParams()
  const issuerInfo = useSelector(({ issuerInfo }) => issuerInfo.issuerInfo, shallowEqual)
  const isLoading = useSelector(({ issuerInfo }) => issuerInfo.isLoading, shallowEqual)

  useEffect(() => {
    dispatch(loadIssuerInfo(issuerId))
  }, [dispatch, issuerId])

  if (isLoading) {
    return <Loading />
  }
  return (
    <Page title='myAMC.ch - Issuer'>
      <div className='header-bg'>
        <header id='topnav2'>

          <div className='container-fluid'>
            <div className='row align-items-center '>

              <div className='col-md-8'>
                <div className='page-title-box'>
                  <h3>{issuerInfo.name}
                    <span style={{ fontSize: '60%', marginLeft: '10px', fontWeight: 'normal', fontStyle: 'italic' }}>{issuerInfo.is_platform ? '(Issuer Platform)' : '(Issuer Firm)'}</span>
                  </h3>
                  {issuerInfo.motto !== undefined
                    ? <h6>{issuerInfo.motto}</h6>
                    : ''}
                  <p>
                    {issuerInfo.description_long !== undefined
                      ? <div dangerouslySetInnerHTML={{ __html: issuerInfo.description_long }} />
                      : (
                          issuerInfo.description !== undefined
                            ? <>{issuerInfo.description}</>
                            : ''
                        )}
                  </p>

                </div>
              </div>

              <div className='col-md-4'>
                <div className='d-md-block w-100' style={{ backgroundColor: issuerInfo.logo_background }}>
                  {issuerInfo.logo !== undefined
                    ? <img src={issuerInfo.logo} alt={issuerInfo.name + ' logo'} width='200' />
                    : ''}
                </div>
              </div>

            </div>

          </div>
        </header>
      </div>

      {(issuerInfo.email !== undefined || issuerInfo.website !== undefined || issuerInfo.phone !== undefined)
        ? <div className='wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <h4 className='mt-0 header-title mb-4'>Contact Us</h4>

                    <div className='d-md-block w-100 mb-4'>
                      <button
                        className='btn btn-primary waves-effect waves-light' type='button' data-toggle='collapse'
                        data-target='#collapseEmail' aria-expanded='false' aria-controls='collapseEmail'
                      >
                        Show Contact Info
                      </button>
                    </div>

                    <div className='row align-items-center '>
                      <div className='col-md-8'>
                        <span className='collapse' id='collapseEmail'>
                          {issuerInfo.website !== undefined
                            ? <ol className='breadcrumb'>
                              <a className='breadcrumb-item active' rel='noreferrer' href={issuerInfo.website} target='_blank'>{cleanURL(issuerInfo.website)}</a>
                              </ol>
                            : ''}
                          {issuerInfo.email !== undefined
                            ? <ol className='breadcrumb'>
                              <a className='breadcrumb-item active' href={'mailto:' + issuerInfo.email}>{issuerInfo.email}</a>
                              </ol>
                            : ''}
                          {issuerInfo.phone !== undefined
                            ? <ol className='breadcrumb'>
                              <a className='breadcrumb-item active' href={'tel:' + issuerInfo.phone}>{issuerInfo.phone}</a>
                              </ol>
                            : ''}
                        </span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        : ''}

      <div className='wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <IssuerCertificateList />
            </div>
          </div>
        </div>
      </div>

    </Page>
  )
}

export default IssuerDetail
