import axios from 'axios'
import * as qs from 'qs'
import config from './../config'

const EMPTY_TOKEN = 'anonymous'
const ROOT = config.api.ROOT

export async function getCertificates (ids, providers, tags, themes, investmentTypes, strategyStyles, strategyTypes, assetClasses, issuers, sortBy, pageLimit) {
  let size = 5000
  if (pageLimit > 0) {
    size = pageLimit
  }
  return axios(
    ROOT + '/certificates' + `?fast=true&promoted=true&access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        ids: ids.join(','),
        provider_ids: providers.map((provider) => provider.id),
        issuer_ids: issuers.map((issuer) => issuer.id),
        tag_ids: tags.map((tag) => tag.id),
        theme_ids: themes.map((theme) => theme.id),
        investment_type_ids: investmentTypes.map((investmentType) => investmentType.id),
        strategy_style_ids: strategyStyles.map((strategyStyle) => strategyStyle.id),
        strategy_type_ids: strategyTypes.map((strategyType) => strategyType.id),
        asset_class_ids: assetClasses.map((assetClass) => assetClass.id),
        sort_by: sortBy,
        size: size
      },
      validateStatus: () => true,
      paramsSerializer: params => qs.stringify(params, { indices: false })
    }
  )
}

export async function getCertificateInfo (certificateId) {
  return axios(
    ROOT + '/certificates/' + certificateId + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      /* headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`
      }, */
      validateStatus: () => true
    }
  )
}

export async function getCertificatePerf (certificateId) {
  return axios(
    ROOT + '/certificates/' + certificateId + '/prices' + `?exhaustive=true&access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,

    {
      params: {
        size: 5000
      },
      validateStatus: () => true
    }
  )
}

export async function getCertificatesPerf (certificateIds) {
  return axios(
    ROOT + '/certificates/prices' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,

    {
      params: {
        ids: certificateIds.join(','),
        size: 10000
      },
      validateStatus: () => true
    }
  )
}

export async function getBenchmarkPerf (benchmarkId) {
  return axios(
    ROOT + '/benchmarks/' + benchmarkId + '/prices' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,

    {
      params: {
        size: 5000
      },
      validateStatus: () => true
    }
  )
}

export async function postCertificateContact (certificateId, data) {
  return axios.post(
    ROOT + '/certificates/' + certificateId + `/contact?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    data,
    {}
  )
}

export async function getProviderInfo (providerId) {
  return axios(
    ROOT + '/providers/' + providerId + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,

    {
      validateStatus: () => true
    }
  )
}

export async function getProvidersByName (name, sortBy) {
  return axios(
    ROOT + '/providers' + `?promoted=true&access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name,
        sort_by: sortBy
      },
      validateStatus: () => true
    }
  )
}

export async function postProviderContact (providerId, data) {
  return axios.post(
    ROOT + '/providers/' + providerId + `/contact?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    data,
    {}
  )
}

export async function getIssuerInfo (issuerId) {
  return axios(
    ROOT + '/issuers/' + issuerId + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,

    {
      validateStatus: () => true
    }
  )
}

export async function getIssuersByName (name, sortBy) {
  return axios(
    ROOT + '/issuers' + `?promoted=true&access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name,
        sort_by: sortBy
      },
      validateStatus: () => true
    }
  )
}

export async function getTagsByName (name) {
  return axios(
    ROOT + '/tags' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name
      },
      validateStatus: () => true
    }
  )
}

export async function getThemesByName (name) {
  return axios(
    ROOT + '/themes' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name
      },
      validateStatus: () => true
    }
  )
}

export async function getStrategyTypesByName (name) {
  return axios(
    ROOT + '/strategytypes' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name
      },
      validateStatus: () => true
    }
  )
}

export async function getStrategyStylesByName (name) {
  return axios(
    ROOT + '/strategystyles' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name
      },
      validateStatus: () => true
    }
  )
}

export async function getInvestmentTypesByName (name) {
  return axios(
    ROOT + '/investmenttypes' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name
      },
      validateStatus: () => true
    }
  )
}

export async function getAssetClassesByName (name) {
  return axios(
    ROOT + '/assetclasses' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name
      },
      validateStatus: () => true
    }
  )
}

export async function getCompanyTypesByName (name) {
  return axios(
    ROOT + '/companytypes' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {
      params: {
        name: name
      },
      validateStatus: () => true
    }
  )
}

export async function getInvestor () {
  return axios(
    ROOT + '/investor' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {}
  )
}

export async function createInvestor () {
  return axios.post(
    ROOT + '/investor' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {},
    {}
  )
}

export async function getPrices () {
  return axios(
    ROOT + '/billing/prices' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {}
  )
}

export async function getBillingConfig () {
  return axios(
    ROOT + '/billing/config' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {}
  )
}

export async function getUserProfile () {
  return axios(
    ROOT + '/user' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    {}
  )
}

function toBool (str) {
  return str === 'true' || str === true || str === 1
}

export async function editUserProfile (data) {
  data.terms_approve = toBool(data.terms_approve)
  data.privacy_approve = toBool(data.privacy_approve)
  data.qualified_approve = toBool(data.qualified_approve)
  data.swiss_approve = toBool(data.swiss_approve)
  return axios.put(
    ROOT + '/user' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    data,
    {}
  )
}

export async function subscribe (data) {
  return axios.post(
    ROOT + '/billing/subscribe' + `?access_token=${window.sessionStorage.getItem('token') || EMPTY_TOKEN}`,
    data,
    {}
  )
}
