import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { editUser, loadCompanyTypes, loadUser } from '../../actions/actionCreators'
import Loading from '../loading'

const ProfileForm = () => {
  const dispatch = useDispatch()
  let { userInfo, isLoading } = useSelector(({ user }) => user, shallowEqual)
  const companyTypes = useSelector(({ companyTypes }) => companyTypes, shallowEqual)
  const userEdit = useSelector(({ userEdit }) => userEdit, shallowEqual)
  if (userEdit.overwrites) {
    userInfo = userEdit.userInfo
  }

  useEffect(() => {
    dispatch(loadUser())
    dispatch(loadCompanyTypes())
  }, [dispatch])

  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm()
  const onSubmit = data => {
    data.company_type = parseInt(data.company_type, 10)
    dispatch(editUser(data))
    setTimeout(function () {
      window.location.href = '/'
    }, 5000)
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <>

      {userInfo.need_recertification
        ? <div className='alert alert-info mt-2 mb-4' role='alert'>
          Before you can continue, we need extra information about you:
          </div>
        : <></>}
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Registered Email</label>
          <div className='col-sm-10'>
            <input className='form-control' type='text' disabled defaultValue={userInfo.email_registered} {...register('email_registered')} />
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>First Name *</label>
          <div className='col-sm-10'>
            <input className='form-control' type='text' defaultValue={userInfo.first_name} {...register('first_name', { required: true })} />
            {errors.first_name && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Last Name *</label>
          <div className='col-sm-10'>
            <input className='form-control' type='text' defaultValue={userInfo.last_name} {...register('last_name', { required: true })} />
            {errors.last_name && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Contact Email</label>
          <div className='col-sm-10'>
            <input className='form-control' type='email' defaultValue={userInfo.email_contact} {...register('email_contact', { required: false })} />
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Contact Phone *</label>
          <div className='col-sm-10'>
            <input className='form-control' type='tel' defaultValue={userInfo.phone} {...register('phone', { required: true })} />
            {errors.phone && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>
        </div>
        <div className='form-group row' style={{ paddingTop: '40px' }}>
          <label className='col-sm-2 col-form-label'>Company Name *</label>
          <div className='col-sm-10'>
            <input className='form-control' type='text' defaultValue={userInfo.company_name} {...register('company_name', { required: true })} />
            {errors.company_name && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Company Type *</label>
          <div className='col-sm-10'>
            <select {...register('company_type', { required: true })} className='btn btn-secondary dropdown-toggle w-100'>
              {companyTypes.companyTypes.map((item, i) => (
                <option selected={item.id === userInfo.company_type} value={item.id}>{item.name}</option>
              ))}
            </select>
            {errors.company_type && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-2 col-form-label'>Company Role</label>
          <div className='col-sm-10'>
            <input className='form-control' type='text' defaultValue={userInfo.company_role} {...register('company_role', { required: true })} />
            {errors.company_role && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>
        </div>

        <div className='form-group row' style={{ paddingTop: '40px' }}>
          <label className='col-sm-2 col-form-label'>Terms and Conditions</label>
          <div className='col-sm-10'>
            <div style={{ textAlign: 'center' }}>I hereby accept the terms and conditions</div>
            <input
              className='form-control' type='checkbox'
              disabled={userInfo.terms_approve === true}
              defaultChecked={userInfo.terms_approve}
              onChange={() => userInfo.terms_approve = !userInfo.terms_approve}
              {...register('terms_approve', { required: !(userInfo.terms_approve === true) })}
            />
            {errors.terms_approve && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>

          <label className='col-sm-2 col-form-label'>Privacy Statement</label>
          <div className='col-sm-10'>
            <div style={{ textAlign: 'center' }}>I hereby accept the privacy statement</div>
            <input
              className='form-control' type='checkbox'
              disabled={userInfo.privacy_approve === true}
              defaultChecked={userInfo.privacy_approve}
              onChange={() => userInfo.privacy_approve = !userInfo.privacy_approve}
              {...register('privacy_approve', { required: !(userInfo.privacy_approve === true) })}
            />
            {errors.privacy_approve && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>

          <label className='col-sm-2 col-form-label'>Qualified Investor</label>
          <div className='col-sm-10'>
            <div style={{ textAlign: 'center' }}>I hereby testify being a qualified investor</div>
            <input
              className='form-control' type='checkbox'
              disabled={userInfo.qualified_approve === true}
              defaultChecked={userInfo.qualified_approve}
              onChange={() => userInfo.qualified_approve = !userInfo.qualified_approve}
              {...register('qualified_approve', { required: !(userInfo.qualified_approve === true) })}
            />
            {errors.qualified_approve && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>

          <label className='col-sm-2 col-form-label'>Swiss Investor</label>
          <div className='col-sm-10'>
            <div style={{ textAlign: 'center' }}>I hereby testify being a Swiss investor</div>
            <input
              className='form-control' type='checkbox'
              disabled={userInfo.swiss_approve === true}
              defaultChecked={userInfo.swiss_approve}
              onChange={() => userInfo.swiss_approve = !userInfo.swiss_approve}
              {...register('swiss_approve', { required: !(userInfo.swiss_approve === true) })}
            />
            {errors.swiss_approve && <div className='alert alert-warning mt-2' role='alert'>
              This field is required
            </div>}
          </div>

          <button
            style={{ marginLeft: '15px' }} className='btn btn-secondary waves-effect waves-light' onClick={() => {
              setValue('swiss_approve', true)
              setValue('qualified_approve', true)
              setValue('privacy_approve', true)
              setValue('terms_approve', true)
            }}
          >
            Approve All Conditions
          </button>
        </div>

        <input style={{ marginTop: '40px', marginLeft: 'auto', marginRight: 'auto' }} className=' btn btn-primary waves-effect waves-light col-sm-12' type='submit' />

        {userEdit.isFailed
          ? <div className='alert alert-danger mt-2' role='alert'>
            Could not store profile information
          </div>
          : <></>}

        {userEdit.overwrites
          ? <div className='alert alert-success mt-2' role='alert'>
            Profile saved successfully
          </div>
          : <></>}
      </form>
    </>
  )
}

export default ProfileForm
